<ngx-spinner type="ball-spin-clockwise-fade" size="medium"></ngx-spinner>
<div class="supplierprofile-nav" aria-label="breadcrumbs" >
  <a [routerLink]="['/suppliers']" #breadCrumbNav aria-label="Back to Quote Requests" tabindex="0">Suppliers</a>
  <span role="navigation" aria-current="currentpage" aria-atomic="true" aria-live="assertive" class="current">{{supplierName}}</span>
</div>
<mat-card class="mat-card-supplier-contact" fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.xs="start start">
    <div fxFlex="7" fxFlex.md="15" fxFlex.sm="20" fxFlex.xs="22">
      <div class="supplier-short-name" >SN</div>
    </div>
    <div class="supplier-contact-detail" fxFlex="73" fxFlex.md="55" fxFlex.sm="35" fxFlex.xs="48">
        <h1 class="supplier-user-name">{{supplierName}}</h1>
    </div>
    <div  fxFlex="15" fxLayoutAlign="end start" fxFlex.md="25" fxFlex.sm="40" fxFlex.xs="30">
      <div fxLayout="row wrap"  fxLayoutAlign="center start" class="supplier-status-container">
        <div fxFLex="100" fxLayoutAlign="center center">
          <span style="font-size: 12px;">Supplier Active</span>
        </div>  
        <div fxFlex="100" fxLayoutAlign="center start">
          <mat-label [ngClass]="true ? 'label-before-slide' : 'label-before-slide-active'">Off</mat-label>
          <mat-slide-toggle class="toggle"  [checked]="supplierStatus === 'Active' ? true : false"   labelPosition="after"></mat-slide-toggle>
          <mat-label [ngClass]="true ? 'label-after-slide' : 'label-after-slide-active'">On</mat-label>
        </div>
      </div>
    </div>
    <div fxFlex="5" fxFlex.md="5" fxFlex.sm="5" fxFlex.xs="3" fxLayoutAlign="end start">
      <div fxLayout="row" fxLayoutAlign="end start">
        <div fxFlex="100" > 
          <mat-icon (click)="openEditDetailsPopup()">edit</mat-icon>
        </div>
      </div>
    </div>
</mat-card>
<mat-accordion class="candidate-review-panel candidate-info-panel" multi="true">
  <!--Candidate Details starts-->
  <mat-expansion-panel class="candidate-detail" tabindex="0">
    <mat-expansion-panel-header [collapsedHeight]="matExpansionHeaderHeight"
      [expandedHeight]="matExpansionHeaderHeight">
      <span class="panel-heading">Supplier Details</span>
    </mat-expansion-panel-header>
    <mat-panel-description>
      <app-supplier-details-edit class="full-stretch" [partyDetails]="partyDetails"></app-supplier-details-edit>
    </mat-panel-description>
  </mat-expansion-panel>
</mat-accordion>
<div *ngIf="contractsData && contractsData.length && contractsFormGroup" [formGroup]="contractsFormGroup">
  <div  formArrayName="contractsArray">
    <div class="supplier-contract-details-section" *ngFor="let contract of contractsFormGroup?.get('contractsArray')['controls']; let i = index;" [formGroupName]="i">
      <mat-accordion class="contract-details-div">
        <mat-expansion-panel class="contract-details-expanstion" tabindex="0" [expanded]="i === 0">
          <mat-expansion-panel-header [collapsedHeight]="matExpansionHeaderHeight"
            [expandedHeight]="matExpansionHeaderHeight" class="contract-details-expanstion-header" (click)="showContractsByIndex[''+i] = !showContractsByIndex[''+i]">
            <div fxLayout="row" class="contract-heading full-stretch">
              <span fxFlex="50" fxLayoutAlign="start center">Contract: {{contract.get('serviceType').value}} </span>
              <mat-icon *ngIf="showContractsByIndex[''+i]" fxFlex="50" fxLayoutAlign="end center" >remove_circle_outline</mat-icon>
              <mat-icon *ngIf="!showContractsByIndex[''+i]" fxFlex="50" fxLayoutAlign="end center" >add_circle_outline</mat-icon>
            </div>
          </mat-expansion-panel-header>
          <mat-panel-description style="display: block;" class="contract-details-content">
              <div  fxLayout="row wrap" fxLayoutAlign="center center" class="contract-details">
                <div fxFlex="30" class="showStatusCardRow" fxLayoutAlign="center center"  fxFlex.md="30" fxFlex.sm="100" fxFlex.xs="100">
                  <div fxLayout="row wrap" fxLayoutAlign="center center" class="contract-status-card">
                    <div fxFlex="100" fxLayoutAlign="center">
                      <span class="contract-status-label">
                        Current Contract Status:
                      </span>
                    </div>
                    <div fxFlex="100" fxLayoutAlign="center">
                      <span class="contract-status-value">
                        Ready for Review
                      </span>
                    </div>
                    <div fxFlex="100" fxLayoutAlign="center">
                      <button class="contract-status-btn" mat-button>
                        Activate Contract
                      </button>
                    </div>
                  </div>
                </div>
                <div fxFlex="68" fxFlex.md="68" fxFlex.sm="100" fxFlex.xs="100">
                  <div fxLayout="row wrap" >
                    <div fxFlex="53" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                      <div fxLayout="row wrap" fxLayoutGap="1em">
                        <div fxFlex="40" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                          <mat-form-field class="contract-mat-form-field">
                            <mat-label>Contract Service Type</mat-label>
                            <input class="businessUnit" matInput  formControlName="serviceType">
                            <mat-icon matSuffix class="contract-email-icon" (click)="updateContractEmail(contract,i)">edit</mat-icon>
                          </mat-form-field>
                        </div>
                        <div fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                          <div fxFlex="45">
                            <mat-form-field class="contract-mat-form-field">
                              <mat-label>Contract Dates</mat-label>
                              <input class="businessUnit" [matDatepicker]="picker" matInput formControlName="effectiveFrom" >
                            </mat-form-field>
                          </div>
                          <div fxFlex="4" fxFlexOffset="3" fxLayoutAlign="center center">
                            -
                          </div>
                          <div fxFlex="45" fxFlexOffset="3">
                            <mat-form-field class="contract-mat-form-field">
                              <input class="businessUnit" [matDatepicker]="picker" matInput  formControlName="effectiveTo">
                            </mat-form-field>
                          </div>
                        </div>
                        <div fxFlex="95" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                          <mat-form-field class="contract-mat-form-field contract-email-field">
                            <mat-label>Notification Email (optional)</mat-label>
                            <input class="businessUnit" matInput  formControlName="email">
                            <mat-icon matSuffix class="contract-email-icon" (click)="updateContractEmail(contract,i)">edit</mat-icon>
                          </mat-form-field>
                         
                        </div>
                      </div>
                    </div>
                    <div fxFlex="47" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                      <div fxLayout="row wrap" fxLayoutGap="1em">
                        <div fxFlex="99">
                          <mat-form-field class="contract-mat-form-field">
                            <mat-label>Affiliation Relation ship</mat-label>
                            <input class="businessUnit" matInput  formControlName="affiliateRelation">
                            <mat-icon matSuffix class="contract-email-icon" (click)="updateContractEmail(contract,i)">edit</mat-icon>
                          </mat-form-field>
                        </div>
                        <div fxFlex="47">
                          <mat-form-field class="contract-mat-form-field">
                            <mat-label>Affiliate of</mat-label>
                            <input class="businessUnit" matInput  formControlName="affiliateOf">
                            <mat-icon matSuffix class="contract-email-icon" (click)="updateContractEmail(contract,i)">edit</mat-icon>
                          </mat-form-field>
                        </div>
                        <div fxFlex="47">
                          <mat-form-field class="contract-mat-form-field">
                            <mat-label>Affiliate ID</mat-label>
                            <input class="businessUnit" matInput  formControlName="affiliateId">
                            <mat-icon matSuffix class="contract-email-icon" (click)="updateContractEmail(contract,i)">edit</mat-icon>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div fxFlex="30" class="showStatusCardColumn" fxLayoutAlign="center center" fxFlexOffset.lg="2%" fxFlexOffset.md="2%" fxFlex.md="30" fxFlex.sm="100" fxFlex.xs="100">
                  <div fxLayout="row wrap" class="contract-status-card">
                    <div fxFlex="100" fxLayoutAlign="center">
                      <span class="contract-status-label">
                        Current Contract Status:
                      </span>
                    </div>
                    <div fxFlex="100" fxLayoutAlign="center">
                      <span class="contract-status-value">
                        Ready for Review
                      </span>
                    </div>
                    <div fxFlex="100" fxLayoutAlign="center">
                      <button class="contract-status-btn" mat-button>
                        Activate Contract
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <mat-accordion   class="candidate-review-panel candidate-info-panel service-details-card" multi="true" formArrayName="territoryServiceDetails">
                <!--Candidate Details starts-->
                <mat-expansion-panel class="candidate-detail" tabindex="0">
                  <mat-expansion-panel-header [collapsedHeight]="matExpansionHeaderHeight"
                    [expandedHeight]="matExpansionHeaderHeight">
                    <span class="panel-heading">Teritory Coverage</span>
                  </mat-expansion-panel-header>
                  <mat-panel-description style="display: block;">
                    <div fxLayout="row" class="full-stretch" *ngFor="let territory of contract.get('territoryServiceDetails')['controls']; let territoryIndex = index;" [formGroupName]="territoryIndex">
                      <div fxLayout="row wrap" *ngIf=" territory && territory.get('deliveryConfigurations').length" class="full-stretch" formArrayName="deliveryConfigurations">
                        <div class="full-stretch" *ngFor="let config of contractsFormGroup?.get('contractsArray').get(''+i).get('territoryServiceDetails').get(''+territoryIndex).get('deliveryConfigurations')['controls']; let configIndex = index;" [formGroupName]="configIndex">
                          <div fxFlex="98" class="teritory-section">       
                            <div fxLayout="row">
                              <div fxFlex="5">
                                <span class="teritory-count-span"> {{configIndex + 1}}</span>
                              </div>
                              <div fxFlex="45">
                                <div fxFlex="50">
                                  <mat-form-field class="teritory-section-form-field"> 
                                    <mat-label>Teritory</mat-label>
                                    <input class="level"  formControlName="region" matInput >
                                </mat-form-field>
                                </div>
                                <div fxFlex="50">
                                  <mat-form-field>
                                    <mat-label>Teritory Tariff</mat-label>
                                    <input class="level" formControlName="tariff" matInput >
                                </mat-form-field>
                                </div>
                              </div>
                              <div fxFlex="50" fxLayoutAlign="end start">
                                <mat-icon (click)="openTeritoryDetails(territory,configIndex)">edit</mat-icon>
                              </div>
                            </div>
                            <div fxLayout="row"  >
                              <div fxLayout="row wrap"  class="full-stretch" fxLayoutGap.lg="2%" >
                                <div fxFlex="49" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" class="configurations-table">
                                  <table mat-table *ngIf="contractsFormGroup?.get('contractsArray').get(''+i).get('territoryServiceDetails').get(''+territoryIndex).get('deliveryConfigurations').get(''+configIndex).get('discounts').value && contractsFormGroup?.get('contractsArray').get(''+i).get('territoryServiceDetails').get(''+territoryIndex).get('deliveryConfigurations').get(''+configIndex).get('discounts').value.length" [dataSource]="getTableData(contractsFormGroup?.get('contractsArray').get(''+i).get('territoryServiceDetails').get(''+territoryIndex).get('deliveryConfigurations').get(''+configIndex).get('discounts'))" class="mat-elevation-z8" formArrayName="discounts">
                    
                                    <!--- Note that these columns can be defined in any order.
                                          The actual rendered columns are set as a property on the row definition" -->
                                  
                                    <!-- category Column -->
                                    <ng-container matColumnDef="category">
                                      <th mat-header-cell *matHeaderCellDef> Discount Type </th>
                                      <td mat-cell *matCellDef="let element; let index = index"  [formGroupName]="index"> {{element.category}} </td>
                                    </ng-container>
                                  
                                    <!-- percentage Column -->
                                    <ng-container matColumnDef="percentage">
                                      <th mat-header-cell *matHeaderCellDef> Discount % </th>
                                      <td mat-cell *matCellDef="let element; let index = index"  [formGroupName]="index"> {{element.percentage}} </td>
                                    </ng-container>
                                  
                                    <!-- effectiveFrom Column -->
                                    <ng-container matColumnDef="effectiveFrom">
                                      <th mat-header-cell *matHeaderCellDef> Start Date </th>
                                      <td mat-cell *matCellDef="let element; let index = index"  [formGroupName]="index"> {{element.effectiveFrom | date:'dd-MM-yyyy'}} </td>
                                    </ng-container>
                                  
                                    <!-- effectiveTo Column -->
                                    <ng-container matColumnDef="effectiveTo">
                                      <th mat-header-cell *matHeaderCellDef> End Date </th>
                                      <td mat-cell *matCellDef="let element; let index = index"  [formGroupName]="index"> {{element.effectiveTo | date:'dd-MM-yyyy'}} </td>
                                    </ng-container>
                                  
                                    <tr mat-header-row *matHeaderRowDef="SupplierDiscountsColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: SupplierDiscountsColumns;"></tr>
                                  </table>
                                  
                                </div>
                                <div fxFlex="49" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" class="configurations-table">
                                  <table mat-table *ngIf="contractsFormGroup?.get('contractsArray').get(''+i).get('territoryServiceDetails').get(''+territoryIndex).get('deliveryConfigurations').get(''+configIndex).get('commissions').value && contractsFormGroup?.get('contractsArray').get(''+i).get('territoryServiceDetails').get(''+territoryIndex).get('deliveryConfigurations').get(''+configIndex).get('commissions').value.length" [dataSource]="getTableData(contractsFormGroup?.get('contractsArray').get(''+i).get('territoryServiceDetails').get(''+territoryIndex).get('deliveryConfigurations').get(''+configIndex).get('commissions'))"  class="mat-elevation-z8" formArrayName="commissions">
                    
                                    <!--- Note that these columns can be defined in any order.
                                          The actual rendered columns are set as a property on the row definition" -->
                                  
                                    <!-- category Column -->
                                    <ng-container matColumnDef="category">
                                      <th mat-header-cell *matHeaderCellDef> Commission Type </th>
                                      <td mat-cell *matCellDef="let element; let index = index"  [formGroupName]="index"> {{element.category}} </td>
                                    </ng-container>
                                  
                                    <!-- percentage Column -->
                                    <ng-container matColumnDef="percentage">
                                      <th mat-header-cell *matHeaderCellDef> Commission % </th>
                                      <td mat-cell *matCellDef="let element; let index = index"  [formGroupName]="index"> {{element.percentage}} </td>
                                    </ng-container>
                                  
                                    <!-- effectiveFrom Column -->
                                    <ng-container matColumnDef="effectiveFrom">
                                      <th mat-header-cell *matHeaderCellDef> Start Date </th>
                                      <td mat-cell *matCellDef="let element; let index = index"  [formGroupName]="index"> {{element.effectiveFrom | date:'dd-MM-yyyy'}} </td>
                                    </ng-container>
                                  
                                    <!-- EndDate Column -->
                                    <ng-container matColumnDef="effectiveTo">
                                      <th mat-header-cell *matHeaderCellDef> End Date </th>
                                      <td mat-cell *matCellDef="let element; let index = index"  [formGroupName]="index"> {{element.effectiveTo | date:'dd-MM-yyyy'}} </td>
                                    </ng-container>
                                  
                                    <tr mat-header-row *matHeaderRowDef="SupplierCommissionColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: SupplierCommissionColumns;"></tr>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div fxFlex="2">
                            <div>
                              <mat-icon (click)="removeTerritory(territory,configIndex)">delete_outline</mat-icon>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div fxLayout="row wrap" class="full-stretch" *ngIf="!contract.get('territoryServiceDetails').length || !contract.get('territoryServiceDetails').get(''+0).get('deliveryConfigurations').length">
                      <div fxFlex="100" fxLayoutAlign="center center">
                        <span class="empty-config-text">
                          You must add Territory Coverage to activate the contract
                        </span>
                      </div>
                      <div fxFlex="100" fxLayoutAlign="center center">
                        <span (click)="addContractTerritory(contract)" class="empty-config-link">
                          Add Territory Coverage
                        </span>
                      </div>
                    </div>
                    <div fxLayout="row" *ngIf=" contract.get('territoryServiceDetails').length && contract.get('territoryServiceDetails').get(''+0).get('deliveryConfigurations').length" fxLayoutAlign="end center">
                      <button  mat-raised-button color="primary" (click)="addContractTerritory(contract)" class="add-teritory-btn">
                        Add Territory Coverage
                      </button>
                    </div>
                  </mat-panel-description>
                </mat-expansion-panel>
              </mat-accordion>
              <mat-accordion class="candidate-review-panel candidate-info-panel service-details-card" multi="true" formArrayName="oracleSiteIdDetails">
                <!--Candidate Details starts-->
                <mat-expansion-panel class="candidate-detail" tabindex="0">
                  <mat-expansion-panel-header [collapsedHeight]="matExpansionHeaderHeight"
                    [expandedHeight]="matExpansionHeaderHeight">
                    <span class="panel-heading">Oracle Site IDs</span>
                  </mat-expansion-panel-header>
                  <mat-panel-description>
                    <div fxLayout="row wrap"  class="full-stretch"  >
                      <div fxLayout="row wrap" class="full-stretch" *ngFor="let siteId of contract.get('oracleSiteIdDetails')['controls']; let siteIdIndex = index;" [formGroupName]="siteIdIndex">
                        <div fxLayout="row wrap" class="full-stretch" *ngIf="siteId && siteId.get('deliveryConfigurations').length" class="full-stretch" formArrayName="deliveryConfigurations">
                          <div fxFlex="100" class="full-stretch site-id-table">
                            <table mat-table [dataSource]="getTableData(siteId.get('deliveryConfigurations'))"  class="mat-elevation-z8">
                  
                              <!--- Note that these columns can be defined in any order.
                                    The actual rendered columns are set as a property on the row definition" -->
                            
                              <!-- Site I Column -->
                              <ng-container matColumnDef="siteId">
                                <th mat-header-cell *matHeaderCellDef> Site ID </th>
                                <td mat-cell *matCellDef="let element; let index = index"  [formGroupName]="index"> {{element.siteId}} <mat-icon (click)="addSiteId(siteId.get('deliveryConfigurations').get(''+index))">edit</mat-icon> </td>
                              </ng-container>
                            
                              <!-- country Column -->
                              <ng-container matColumnDef="country">
                                <th mat-header-cell *matHeaderCellDef>Country </th>
                                <td mat-cell *matCellDef="let element; let index = index"  [formGroupName]="index"> {{element.country}} </td>
                              </ng-container>
                            
                              <!-- StartDate Column -->
                              <ng-container matColumnDef="StartDate">
                                <th mat-header-cell *matHeaderCellDef> Start Date </th>
                                <td mat-cell *matCellDef="let element; let index = index"  [formGroupName]="index"> {{element.StartDate}} </td>
                              </ng-container>
                            
                              <!-- streetAddress Column -->
                              <ng-container matColumnDef="streetAddress">
                                <th mat-header-cell *matHeaderCellDef> street Address </th>
                                <td mat-cell *matCellDef="let element; let index = index"  [formGroupName]="index"> {{element.streetAddress}} </td>
                              </ng-container>
                  
                                <!-- city Column -->
                                <ng-container matColumnDef="city">
                                <th mat-header-cell *matHeaderCellDef> City </th>
                                <td mat-cell *matCellDef="let element; let index = index"  [formGroupName]="index"> {{element.city}} </td>
                              </ng-container>
                  
                                <!-- state Column -->
                                <ng-container matColumnDef="state">
                                <th mat-header-cell *matHeaderCellDef> State </th>
                                <td mat-cell *matCellDef="let element; let index = index"  [formGroupName]="index"> {{element.state}} </td>
                              </ng-container>
                  
                                <!-- postalCode Column -->
                                <ng-container matColumnDef="postalCode">
                                <th mat-header-cell *matHeaderCellDef> Street Address </th>
                                <td mat-cell *matCellDef="let element; let index = index"  [formGroupName]="index"> {{element.postalCode}} </td>
                              </ng-container>
                  
                              <!-- Status Column -->
                              <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef> Active </th>
                                <td mat-cell *matCellDef="let element; let index = index"  [formGroupName]="index">
                                  <div class="supplier-status-container">
                                    <mat-label [ngClass]="true ? 'label-before-slide' : 'label-before-slide-active'">Off</mat-label>
                                    <mat-slide-toggle class="toggle" formControlName="status" [checked]="element.status === 'Active' ? true : false"  labelPosition="after"></mat-slide-toggle>
                                    <mat-label [ngClass]="true ? 'label-after-slide' : 'label-after-slide-active'">On</mat-label>
                                  </div>
                                </td>
                              </ng-container>
                            
                              <tr mat-header-row *matHeaderRowDef="ContractSiteIdColumns"></tr>
                              <tr mat-row *matRowDef="let row; columns: ContractSiteIdColumns;"></tr>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div fxLayout="row wrap" class="full-stretch" *ngIf="!contract.get('oracleSiteIdDetails').length || !contract.get('oracleSiteIdDetails').get(''+0).get('deliveryConfigurations').length">
                        <div fxFlex="100" fxLayoutAlign="center center">
                          <span class="empty-config-text">
                            You must add the related Site IDs for Oracle to activate the contract
                          </span>
                        </div>
                        <div fxFlex="100" fxLayoutAlign="center center">
                          <span (click)="addSiteId(undefined,i)" class="empty-config-link">
                            Add Site ID
                          </span>
                        </div>
                      </div>
                      <div fxLayout="row wrap" class="full-stretch" *ngIf="contract.get('oracleSiteIdDetails').length && contract.get('oracleSiteIdDetails').get(''+0).get('deliveryConfigurations').length">
                        <div fxFlex="100" fxLayoutAlign="end center">
                          <button  mat-raised-button color="primary" (click)="addSiteId(undefined,i)" class="add-teritory-btn">
                            Add Site ID
                          </button>
                        </div>
                      </div>
                    </div>
                  </mat-panel-description>
                </mat-expansion-panel>
              </mat-accordion>
 
          </mat-panel-description>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
  
</div>
<div fxLayout="row wrap" class="add-contract-btn-row">
  <div fxFlex="100" fxLayoutAlign="end center" fxLayoutAlign.md="center center" fxLayoutAlign.sm="center center" fxLayoutAlign.xs="center center">
    <button mat-raised-button color="primary" (click)="addContract()" class="add-contract-btn">Add Contract</button>
  </div>
</div>