<footer>
  <div class='some-page-wrapper' role="navigation">
      <div class='row'>
        <div class='column'>
          <div class="copyright" [innerHTML]="copyright"></div>
        </div>
        <div class='column'>
          <div class="footer-link" role="navigation">
              <span class="link"><a [href]="termsOfUseUrl" target="_blank" tabindex="0">Terms of Use</a></span>
              <span class="link"><a [href]="privacyNotice" target="_blank" tabindex="0">Privacy Notice</a></span>
              <span class="link contact"><a [href]="contactUs" target="_blank" tabindex="0">Contact Us</a></span>
          </div>
        </div>
      </div>
    </div>
</footer>
