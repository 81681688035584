import { Component } from '@angular/core';

@Component({
  selector: 'app-filter-modal',
  templateUrl: './filter-modal.component.html',
  styleUrls: ['./filter-modal.component.scss']
}) 
export class FilterModalComponent {

  constructor() { }

}
