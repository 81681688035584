/**
 * model for the supplier list filter
 */

type _status = 'Active' | 'Inactive' | 'Ready for Review';

export interface State {
  state: string;
  selected: boolean;
}

export interface City {
  city: string;
  state: string;
  selected: boolean;
}

export interface MatchedLocations {
  states: State[];
  cities: City[];
  statesInitials: string[];
  citiesInitials: string[];
}

interface ContractStatuses {
  status: _status;
  selected: boolean;
}

export interface SupplierListFilter {
  searchTerm: string;
  matchedLocations: MatchedLocations;
  matchedLocationsSelected: number;
  contractStatuses: ContractStatuses[];
  contractStatusesSelected: number;
}
