import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SupplierListItem } from 'src/app/core/models/supplier.model';

@Component({
  selector: 'app-supplier-list-deactivate-pop-up',
  templateUrl: './supplier-list-deactivate-pop-up.component.html',
  styleUrls: ['./supplier-list-deactivate-pop-up.component.scss']
})
export class SupplierListDeactivatePopUpComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SupplierListDeactivatePopUpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SupplierListItem) { }

  supplierName: string;
  deactivateSupplier = false;

  ngOnInit() {
    this.supplierName = this.data['supplier'].details.party.entityName;
  }

  onNoClick(evt): void {
    evt.preventDefault();
    this.deactivateSupplier = false;
    this.dialogRef.close(this.deactivateSupplier);
  }

  onDeactivate() {
    this.deactivateSupplier = true;
    this.dialogRef.close(this.deactivateSupplier);
  }

  onCancel() {
    this.deactivateSupplier = false;
    this.dialogRef.close(this.deactivateSupplier);
  }

}
