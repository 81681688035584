import { NgxSpinnerService } from 'ngx-spinner';
import { Component, OnInit, ViewChild, ViewEncapsulation, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Selection } from '../../../../core/models/selection.model';
import { Subscription, Subject } from 'rxjs';
import { NotificationsService } from '../../../services/notifications.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { LoggerService } from '../../../services/logger.service';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { FilterModalComponent } from '../../filter-modal/filter-modal.component';
import { SuppliersService } from '../../../services/suppliers.service';
import { SupplierListItem } from '../../../models/supplier.model';
import { SupplierListColumnPopUpComponent } from './supplier-list-column-pop-up/supplier-list-column-pop-up.component';
import { SupplierListFilterPopUpComponent } from './supplier-list-filter-pop-up/supplier-list-filter-pop-up.component';
import { skipRecordCount } from 'src/app/core/models/constants';
import { SupplierDetailsEditComponent } from '../supplier-info/supplier-details-edit/supplier-details-edit.component';
import { SupplierListDeactivatePopUpComponent } from './supplier-list-deactivate-pop-up/supplier-list-deactivate-pop-up.component';
import { SupplierListFilter } from '../../../models/supplier-list-filter.model';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-supplier-list',
  templateUrl: './supplier-list.component.html',
  styleUrls: ['./supplier-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SupplierListComponent implements OnInit, OnDestroy {

  /**stores the details of the supplier */
  supplierListItem: SupplierListItem;
  suppliersSubscription: Subscription;
  suppliers: SupplierListItem[] = [];
  /**data source for table */
  dataSource: MatTableDataSource<SupplierListItem>;
  /**stores the displayed columns */
  displayedColumns: string[] = [
    'supplierName',
    'location',
    'contractStatus',
    'supplierActive'
  ];
  /**stores the available columns */
  columnList: string[] = [
  'supplierName',
  'supplierLegalName',
  'supplierVendorNo',
  'location',
  'contractStatus',
  'supplierActive'
  ];
  /**stores the selected columns */
  selectedCols: Selection[];
   /**stores the popup filter options */
  filter: SupplierListFilter = {
    searchTerm: '',
    matchedLocations: { states: [], cities: [], statesInitials: [], citiesInitials: [] },
    matchedLocationsSelected: 0,
    contractStatuses: [
      { status: 'Active', selected: true },
      { status: 'Inactive', selected: true },
      { status: 'Ready for Review', selected: true }
    ],
    contractStatusesSelected: 0
  };
  /** To sort the mat table columns */
  @ViewChild(MatSort, { static: false }) set sortOrder(sort: MatSort) {
    if (sort) {
      this.dataSource.sort = sort;
    }
  }
  /** To paginate in a mat table */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  private searchterms = new Subject<string>();
  /** store default column for sorting */
  sortBy = 'supplierName';
  /** sorting direction */
  sortDir = 'Asc';
  /**check if table data is loaded */
  isLoaded = false;
  totalSuppliers = '0';
  /** Subscription prop for unsubscribing services */
  private readonly subscription: Subscription = new Subscription();
  /**stores count of active suppliers */
  initialCount = 0;
  /**default pagination */
  pageInfo = {
    pageSize: 20,
    pageIndex: 0,
    totalCount: 0
  };
  /**supplier list data error */
  errorMessage = 'We are unable to fetch the Supplier List at this time. Please try again later.';
  /**store the filter value */
  filterText = '';
  /** accesssibility statement */
  sortStatement = '';
  filterDialogRef: MatDialogRef<FilterModalComponent>;
  announceStmt: string;
  /**
  * injecting dependencies
  * @param dialog object for matdialog
  * @param candidateProfilesService object for CandidateProfileService
  * @param changeDetectorRefs object for changeDetectorRefs object
  * @param snackBar object for snachbar
  * @param datePipe object for datePipe object
  * @param spinner object for spinner
  */
   constructor(
     public dialog: MatDialog,
     private readonly suppliersService: SuppliersService,
     private readonly changeDetectorRefs: ChangeDetectorRef,
     public snackBar: MatSnackBar,
     public datePipe: DatePipe,
     private readonly _router: Router,
     public spinner: NgxSpinnerService,
     private readonly Logger: LoggerService,
     private readonly notificationsService: NotificationsService
   ) { }

   loadSuppliers(pageSize: number = 20, skipRecords: number = 0) {
     this.spinner.show();
     this.announceStmt = 'Loading, please wait.';
     this.suppliersSubscription = this.suppliersService.getAllSuppliers(this.filterText, skipRecords, pageSize, this.sortBy, this.sortDir)
     .subscribe({
       next: data => {
         this.suppliers = data;
         setTimeout(() => {
           // TODO: Need API to return total number of suppliers regardless of skip / limit / filter options for paginator to work
           this.paginator.length = data.length;
         });
         this.dataSource = new MatTableDataSource(this.suppliers);
         this.announceStmt = this.suppliers.length > 0 ? `${this.suppliers.length} suppliers found` : 'No records found';
         this.dataSource.sort = this.sortOrder;
         // TODO: Need API to return total number of suppliers regardless of skip / limit / filter options for paginator to work
         this.totalSuppliers = data.length;
         this.spinner.hide();
        },
      error: (err: HttpErrorResponse) => {
         this.spinner.hide();
         this.announceStmt = 'An error occurred. Please try again later.';
         console.log('HTTP Error', err);
         this.notificationsService.flashNotification(
           'error',
           err.message,
           true,
           'dismiss'
         );
       }
      });
   }

   /**To initialize the component */
   ngOnInit() {
     this.loadSuppliers();
     this.subscribeTosearchTerm();
   }

   subscribeTosearchTerm() {
     this.searchterms.pipe(
       debounceTime(1000),
       distinctUntilChanged()
     ).subscribe(text => {
       if (text.length >= 2) {
         this.paginator.pageIndex = skipRecordCount;
         this.loadSuppliers(this.paginator.pageSize);
       } else {
         this.loadSuppliers(this.paginator.pageSize, (this.paginator.pageIndex * this.paginator.pageSize));
       }
     });
   }

   applyFilter(searchTerm) {
     if (searchTerm.length >= 2) {
       this.searchterms.next(searchTerm);
     } else {
       this.searchterms.next('');
     }
   }

   sortData(event) {
     switch (event.active) {
       case 'supplierName':
         event.active = 'supplierName';
         break;
       case 'supplierLegalName':
         event.active = 'supplierLegalName';
         break;
       case 'supplierVendorNo':
         event.active = 'supplierVendorNo';
         break;
       case 'location':
         event.active = 'location';
         break;
       case 'contractStatus':
         event.active = 'contractStatus';
         break;
       case 'supplierActive':
         event.active = 'supplierActive';
         break;
      }
      switch (event.direction) {
        case 'asc':
         this.sortStatement = `sorting ${event.active} in ascending order`;
         break;
       case 'desc':
         this.sortStatement = `sorting ${event.active} in descending order`;
         break;
       case '':
         this.sortStatement = `sorting ${event.active} in random order`;
         break;
     }
     this.sortBy = event.active;
     this.sortDir = event.direction === 'desc' ? 'Dsc' : 'Asc';
     this.loadSuppliers(this.pageInfo.pageSize, this.paginator.pageIndex * this.paginator.pageSize);
     if (event.active === 'supplierName') {
       this.sortBy = 'supplierName';
     }
   }
   /* Refresh and detect the changes */
   refresh() {
     this.changeDetectorRefs.detectChanges();
   }

   /** navigate to supplier details of requested supplier*/
   navigateToSupplierDetails(supplier: SupplierListItem, event) {
     event.preventDefault();
     event.stopPropagation();
     this._router.navigate(['supplier/detail'], {
       state: supplier
     });
   }

   /**destroys the object */
   ngOnDestroy() {
     this.suppliersSubscription.unsubscribe();
   }

   /**pagination */
   onPagination(event: any) {
     this.pageInfo.pageSize = this.paginator.pageSize;
     this.pageInfo.totalCount = this.paginator.length;
      console.log(this.paginator.pageSize);
      console.log(this.paginator.pageIndex);
      this.loadSuppliers(this.paginator.pageSize, (this.paginator.pageIndex * this.paginator.pageSize));
   }

    /** Method to open column selection dialog **/
    openTableOptions() {
     const dialogRef = this.dialog.open(SupplierListColumnPopUpComponent, {
       disableClose: false,
       panelClass: ['dialogMainContainer', 'dialogMainContainerSupplierColumnsTable'],
       data: this.displayedColumns
     });
     this.subscription.add(
       dialogRef.componentInstance.columnsListUpdated.subscribe(
         (result: Selection[]) => {
           this.selectedCols = result;
           this.updateTable();
         })
     );
   }

   /** This method is to update the table display columns based on column selection **/
   updateTable(): void {
     this.displayedColumns = Object.assign([], this.columnList);
     this.columnList.forEach((item, index) => {
       const checkedvalue = this.selectedCols.some(val => {
         return val.value === item;
       });
       if (!checkedvalue) {
         const itemIndex = this.displayedColumns.findIndex(x => x === item);
         this.displayedColumns.splice(itemIndex, 1);
       }
     });
   }

   resetList() {
     this.filterText = '';
     this.spinner.show();
     this.announceStmt = 'Loading, please Wait.';
     this.searchterms.next(this.filterText);
   }

   openFilterOptions() {
     const dialogRef = this.dialog.open(SupplierListFilterPopUpComponent, {
      disableClose: false,
      panelClass: ['dialogMainContainer'],
      data: this.filter
    });
    this.subscription.add(
      dialogRef.componentInstance.filterUpdated.subscribe(
        (result: any) => {
          this.filter = result;
          console.log(JSON.stringify(this.filter));
        })
    );
   }

   toggleActiveConfirm(event: any, supplier: SupplierListItem) {
    const isSupplierActive: boolean = this.supplierActive(supplier);
    if (!isSupplierActive) {
      event.source.checked = isSupplierActive;
      return; // TODO: do we need code to activate an inactive supplier?
    }
    const dialogRef = this.dialog.open(SupplierListDeactivatePopUpComponent, {
      disableClose: false,
      panelClass: ['dialogSupplierMainContainer'],
      data: { supplier: supplier }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // TODO: make call to deactivate supplier
      } else {
        event.source.checked = isSupplierActive;
      }
    });
   }

   addNewSupplier () {
    const dialogRef = this.dialog.open(SupplierDetailsEditComponent, {
      disableClose: false,
      panelClass: ['dialogMainContainer', 'dialogMainContainerSupplierEdit'],
      data: {isModelView: true, action: 'add'}
    });
    dialogRef.afterClosed().subscribe(result => {
        console.log(result);
    });
   }

   supplierActive(supplier: SupplierListItem) {
     const now = new Date();
     if (supplier.details.party && supplier.details.party.roles) {
       return supplier.details.party.roles.filter(r => {
         return r.name === 'supplier' &&
         (r.fromDate && new Date(r.fromDate) <= now) &&
         ((r.toDate && new Date (r.toDate) <= now) || !r.toDate);
        }).length > 0;
      } else {
        return false;
      }
    }
}
