<div style="overflow-y: auto; overflow-x: hidden;" *ngIf="deliveryConfigurationFormGroup" [formGroup]="deliveryConfigurationFormGroup">
    <div fxLayout="row wrap">
        <h4 fxFlex="70" class="header-label">{{isEditView ? 'Edit Territory Coverage' : 'Add Territory Coverage'}}</h4>
        <div fxFlex="30" fxLayoutAlign="end start">
            <mat-icon class="close-icon" (click)="onCloseClick()">close</mat-icon>
        </div>
    </div>
    <div fxLayout="row wrap">
        <div fxFlex="100">
            <mat-form-field >
                <mat-label>Territory</mat-label>
                <mat-select formControlName="region">
                  <mat-option *ngFor="let territory of territoryList" [value]="territory.value">{{territory.displayValue}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex="100">
            <mat-form-field >
                <mat-label>Territory Tariff</mat-label>
                <mat-select formControlName="tariff">
                  <mat-option *ngFor="let tariff of territoryTariffList"  [value]="tariff.value">{{tariff.displayValue}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="supplier-section" formArrayName="discounts">
        <div fxLayout="row wrap" *ngFor="let discount of deliveryConfigurationFormGroup.get('discounts')['controls']; let discountsIndex = index;" [formGroupName]="discountsIndex">
            <div fxFlex="100">
                <span class="supplier-section-label">Supplier Discounts (optional)</span>
            </div>
            <div fxFlex="95" class="discounts-details-section">
                <div fxLayout="row wrap">
                    <div fxFlex="48">
                        <mat-form-field >
                            <mat-label>Discount Type</mat-label>
                            <mat-select formControlName="category">
                              <mat-option *ngFor="let discount of discountTypesList" [value]="discount.value">{{discount.displayValue}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div fxFlex="48" fxFlexOffset="4">
                        <mat-form-field>
                            <mat-label>Discount %</mat-label>
                            <input formControlName="percentage" class="businessUnit" matInput  >
                        </mat-form-field>
                    </div>
                    <div fxFlex="48">
                        <mat-form-field class="example-full-width" >
                            <mat-label>Start date</mat-label>
                            <input matInput [matDatepicker]="picker1" formControlName="effectiveFrom">
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                          </mat-form-field>
                    </div>
                    <div fxFlex="48" fxFlexOffset="4">
                        <mat-form-field class="example-full-width" >
                            <mat-label>End date(optional)</mat-label>
                            <input matInput [matDatepicker]="picker2" formControlName="effectiveTo">
                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                          </mat-form-field>
                    </div>
                </div>
            </div>
            <div fxFlex="5">
                <mat-icon (click)="removeDiscount(configIndex,discountsIndex)">delete_outline</mat-icon>
            </div>
        </div>
        <div fxLayout="row">
            <div fxFlex="auto" fxLayoutAlign="start center"  class="add-commision-icon">
                <mat-icon (click)="addNewDiscount(configIndex)">add_circle</mat-icon>
                <span (click)="addNewDiscount(configIndex)">Add Supplier Discount</span>
            </div>
        </div>
    </div>
    <div class="supplier-section" formArrayName="commissions">
        <div fxLayout="row wrap" *ngFor="let commission of deliveryConfigurationFormGroup.get('commissions')['controls']; let commissionsIndex = index;" [formGroupName]="commissionsIndex">
            <div fxFlex="100">
                <span class="supplier-section-label">Commissions (optional)</span>
            </div>
            <div fxFlex="95" class="discounts-details-section">
                <div fxLayout="row wrap" >
                    <div fxFlex="48">
                        <mat-form-field >
                            <mat-label>Commission Type</mat-label>
                            <mat-select formControlName="category">
                              <mat-option *ngFor="let commission of commissionTypesList"  [value]="commission.value">{{commission.displayValue}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div fxFlex="48" fxFlexOffset="4">
                        <mat-form-field>
                            <mat-label>commission %</mat-label>
                            <input class="businessUnit" matInput formControlName="percentage" >
                        </mat-form-field>
                    </div>
                    <div fxFlex="48">
                        <mat-form-field class="example-full-width" >
                            <mat-label>Start date</mat-label>
                            <input matInput [matDatepicker]="picker3" formControlName="effectiveFrom">
                            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                            <mat-datepicker #picker3></mat-datepicker>
                          </mat-form-field>
                    </div>
                    <div fxFlex="48" fxFlexOffset="4">
                        <mat-form-field class="example-full-width" >
                            <mat-label>End date(optional)</mat-label>
                            <input matInput [matDatepicker]="picker4" formControlName="effectiveTo">
                            <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                            <mat-datepicker #picker4></mat-datepicker>
                          </mat-form-field>
                    </div>
                </div>
            </div>
            <div fxFlex="5">
                <mat-icon (click)="removeCommissions(configIndex,commissionsIndex)">delete_outline</mat-icon>
            </div>
        </div>
        <div fxLayout="row">
            <div fxFlex="auto"  fxLayoutAlign="start center" class="add-commision-icon">
                <mat-icon (click)="addNewCommission(configIndex)">add_circle</mat-icon>
                <span (click)="addNewCommission(configIndex)">Add commission</span>
            </div>
        </div>
    </div>
    
</div>
<mat-dialog-actions align="end" >
    <button mat-button color="primary" (click)="onCloseClick()">CANCEL</button>
    <button mat-raised-button color="primary" [disabled]="isFormInvalid()" (click)="saveChanges()" class="save_btn" [ngClass]="{'save_btn_disabled':isFormInvalid()}" >Save</button>
</mat-dialog-actions>