<div [formGroup]="contractFormGroup">
    <div fxLayout="row wrap">
        <h4 fxFlex="70" class="header-label"> {{isEditView ? 'Edit Supplier Contract' : 'Add Supplier Contract' }}</h4>
        <div fxFlex="30" fxLayoutAlign="end start">
            <mat-icon class="close-icon" (click)="onCloseClick()">close</mat-icon>
        </div>
    </div>
    <div fxLayout="row wrap" class="contract-details-row">
        <div  fxLayout="row" class="full-stretch">
            <div fxFlex="100">
                <mat-form-field >
                    <mat-label>Contract Service Type</mat-label>
                    <mat-select formControlName="serviceType">
                      <mat-option *ngFor="let service of contractServiceTypes" [value]="service.value">{{service.displayValue}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" class="full-stretch" >
            <div fxFlex="45">
                <mat-form-field class="example-full-width">
                    <mat-label>Start date</mat-label>
                    <input matInput [matDatepicker]="picker1" formControlName="effectiveFrom">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                  </mat-form-field>
            </div>
            <div fxFlex="45" fxFlexOffset="10">
                <mat-form-field class="example-full-width">
                    <mat-label>End date (optional)</mat-label>
                    <input matInput [matDatepicker]="picker2" formControlName="effectiveTo">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                  </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" class="full-stretch" >
            <div fxFlex="100">
                <mat-form-field >
                    <mat-label>Affiliation Relationship</mat-label>
                    <mat-select formControlName="affiliateRelation">
                      <mat-option *ngFor="let relation of affiliateRelationsList" [value]="relation.value">{{relation.displayValue}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" class="full-stretch">
            <div fxFlex="45">
                <mat-form-field >
                    <mat-label>Affiliate of</mat-label>
                    <mat-select formControlName="affiliateOf">
                      <mat-option *ngFor="let affiliate of affiliateList" [value]="affiliate.value">{{affiliate.displayValue}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="45" fxFlexOffset="10">
                <mat-form-field>
                    <mat-label>Affiliate ID</mat-label>
                    <input class="businessUnit" matInput  formControlName="affiliateId">
                </mat-form-field>
            </div>
        </div>   
    </div>
    <div fxLayout="row">
        <div  fxFlex="100">
            <mat-form-field>
                <mat-label>Notification Email (optional)</mat-label>
                <input class="businessUnit" matInput  formControlName="email">
            </mat-form-field>
        </div>
    </div>
    <mat-dialog-actions align="end" >
        <button mat-button color="primary" (click)="onCanceleClick()" >CANCEL</button>
        <button mat-raised-button color="primary" (click)="saveChanges()" [disabled]="isFormInvalid()" class="save_btn" [ngClass]="{'save_btn_disabled':isFormInvalid()}">{{!isEditView ? 'Create Contract' : 'Save Changes' }}</button>
    </mat-dialog-actions>
</div>
