<div class="contract-status-container" fxLayout="row" fxLayoutAlign="start start">
    <!-- Renew icon -->
    <div class="contract-status-inner">
        <mat-icon class="contract-status-icon" [ngClass]="reviewCount > 0 ? 'review-icon-on' : 'icon-off'">notification_important</mat-icon><br />
        <span>Review: {{reviewCount}}</span>
    </div>
    <!-- Active icon -->
    <div class="contract-status-inner">
        <mat-icon class="contract-status-icon" [ngClass]="activeCount > 0 ? 'active-icon-on' : 'icon-off'">autorenew</mat-icon><br />
        <span>Active: {{activeCount}}</span>
    </div>
    <!-- Inactive icon -->
    <div class="contract-status-inner">
        <mat-icon class="contract-status-icon" [ngClass]="inactiveCount > 0 ? 'inactive-icon-on' : 'icon-off'">do_not_disturb_on</mat-icon><br />
        <span>Inactive: {{inactiveCount}}</span>
    </div>
</div>