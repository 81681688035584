import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription, Subject, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { SuppliersService } from '../../../../services/suppliers.service';
import { NotificationsService } from '../../../../services/notifications.service';
import { SupplierListFilter, MatchedLocations, City, State } from '../../../../models/supplier-list-filter.model';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-supplier-list-filter-pop-up',
  templateUrl: './supplier-list-filter-pop-up.component.html',
  styleUrls: ['./supplier-list-filter-pop-up.component.scss']
})

export class SupplierListFilterPopUpComponent implements OnInit {

  /** value for media observer */
  gridColumn: number;
  /** media subscription */
  mediaSubscription: Subscription;
  /** values used for the location search */
  locationsSubscription: Subscription;
  locations: any = [];
  matchedLocations: any = [];
  locationsSelected = 0;
  statusesSelected = 0;
  searchInput: string;
  searchTerm$ = new Subject<string>();
  searchSubscription: Subscription;
  filter: SupplierListFilter;
  /** It Emits the data to the parent component */
  @Output() filterUpdated = new EventEmitter<SupplierListFilter>();

  constructor(
    public dialogRef: MatDialogRef<SupplierListFilterPopUpComponent>,
    private readonly mediaObserver: MediaObserver,
    private readonly suppliersService: SuppliersService,
    private readonly notificationsService: NotificationsService,
    @Inject(MAT_DIALOG_DATA) public data: SupplierListFilter) {
    this.mediaSubscription = mediaObserver
      .asObservable()
      .subscribe((val: MediaChange[]) => {
        if (val[0].mqAlias === 'xs') {
          this.gridColumn = 1;
        } else {
          this.gridColumn = 2;
        }
      });

    this.matchLocations(this.searchTerm$).subscribe((results) => {
      const matchedLocations: MatchedLocations = {
        states: [],
        cities: [],
        statesInitials: [],
        citiesInitials: []
      };
      results.forEach(result => {
        if (result.state && matchedLocations.states.filter(item => item.state === result.state).length === 0) {
          matchedLocations.states.push({state: result.state, selected: false});
          matchedLocations.statesInitials.push(result.state.substring(0, 1).toUpperCase());
        }
        if (result.city && matchedLocations.cities.filter(item => item.city === result.city).length === 0) {
          matchedLocations.cities.push({city: result.city, state: result.state, selected: false});
          matchedLocations.citiesInitials.push(result.city.substring(0, 1).toUpperCase());
        }
      });
      matchedLocations.statesInitials.sort();
      matchedLocations.citiesInitials.sort();
      this.filter.matchedLocations = matchedLocations;
      this.searchInput = this.filter.searchTerm;
      this.updateSelected();
    });
  }

  ngOnInit() {
    this.loadLoactions();
    this.filter = this.data;
    this.searchInput = this.filter.searchTerm;
    this.updateSelected();
  }

  loadLoactions() {
    this.locationsSubscription = this.suppliersService
      .getSupplierLocations()
      .subscribe({
        next: data => {
          this.locations = data;
        },
        error: (err: HttpErrorResponse) => {
          console.log('HTTP Error', err);
          this.notificationsService.flashNotification(
            'error',
            err.message,
            true,
            'dismiss'
          );
        }
    });
  }

  matchLocations(searchTerm: Observable<string>) {
    return searchTerm.pipe(
      debounceTime(800),
      distinctUntilChanged(),
      map(term => this.searchEntries(term))
    );
  }

  searchEntries(term) {
    this.filter.searchTerm = term;
    if (term.length === 0) { return []; }
    const matched = this.locations.filter((location) => {
      return (location.city && location.city.toLowerCase().includes(term.toLowerCase())) ||
             (location.state && location.state.toLowerCase().includes(term.toLowerCase())) ||
             (location.country && location.country.toLowerCase().includes(term.toLowerCase()));
    });
    return matched;
  }

  changeState(location, $event) {
    this.filter.matchedLocations.states = this.filter.matchedLocations.states.map(item => {
      const selected = location.state === item.state ? $event.checked : item.selected;
      return {...item, selected: selected };
    });
    this.updateSelected();
  }

  changeCity(location, $event) {
    this.filter.matchedLocations.cities = this.filter.matchedLocations.cities.map(item => {
      const selected = location.city === item.city ? $event.checked : item.selected;
      return {...item, selected: selected };
    });
    this.updateSelected();
  }

  changeStatus(contractStatus, $event) {
    this.filter.contractStatuses = this.filter.contractStatuses.map(item => {
      const selected = contractStatus.status === item.status ? $event.checked : item.selected;
      return {...item, selected: selected };
    });
    this.updateSelected();
  }

  toggleAllLocations($event) {
    this.filter.matchedLocations.states = this.filter.matchedLocations.states.map(item => {
      return {...item, selected: $event.checked };
    });
    this.filter.matchedLocations.cities = this.filter.matchedLocations.cities.map(item => {
      return {...item, selected: $event.checked };
    });
    this.updateSelected();
  }

  clearAllLocations(evt) {
    evt.preventDefault();
    this.filter.matchedLocations.states = this.filter.matchedLocations.states.map(item => {
      return {...item, selected: false };
    });
    this.filter.matchedLocations.cities = this.filter.matchedLocations.cities.map(item => {
      return {...item, selected: false };
    });
    this.updateSelected();
  }

  clearAllStatuses(evt) {
    evt.preventDefault();
    this.filter.contractStatuses = this.filter.contractStatuses.map(item => {
      return {...item, selected: false };
    });
    this.updateSelected();
  }

  onNoClick(evt) {
    evt.preventDefault();
    this.dialogRef.close();
  }

  save() {
    this.dialogRef.close();
    this.filterUpdated.emit(this.filter);
  }

  cancel() {
    this.dialogRef.close();
  }

  updateSelected() {
    const locationsSelectedCount = this.filter.matchedLocations.states.filter(location => location.selected).length +
                                   this.filter.matchedLocations.cities.filter(location => location.selected).length;
    const contractStatusesSelectedCount = this.filter.contractStatuses.filter(status => status.selected).length;
    this.locationsSelected = locationsSelectedCount;
    this.statusesSelected = contractStatusesSelectedCount;
    this.filter.matchedLocationsSelected = locationsSelectedCount;
    this.filter.contractStatusesSelected = contractStatusesSelectedCount;
  }

  clearInput() {
    this.searchInput = '';
    this.searchTerm$.next('');
  }

}
