<ngx-spinner type="ball-spin-clockwise-fade" size="medium"></ngx-spinner>
<div>
    <p class="addLead" [routerLink]="['/hotel-leads/entryForm']">
        <span class="material-icons"> add_circle</span>
    </p>
    <h1 class="formHeader center">Hotel Leads Lists</h1>
</div>

<section class="search_table">
    <div fxLayout="row" fxLayoutAlign="start">
        <span fxFlex="60%" fxLayoutAlign="start">
        <mat-form-field appearance="outline" class="search-within-table search-box">
            <mat-icon matPrefix>search</mat-icon>
            <input matInput id="searchInput" (input)="applyFilter($event.target.value)"
            placeholder="Search with File ID" width="80%" fxFlex.sm="65" aria-label="Search within table">
        </mat-form-field>
        </span>
        <div fxFlex="40%" fxLayoutAlign="start">
            <mat-checkbox class="example-margin subCheckBox" [labelPosition]="'after'" [(ngModel)] = "onlyShowMyOrders" (ngModelChange)="submissionValChange()">
                Only show my submissions
            </mat-checkbox>
        </div>
    </div>
</section>

<section class="mat-elevation-z8" *ngIf="!isNoRecordsFound">
    <table mat-table [dataSource]="leadsListData" class="mat-elevation-z8" matSort
    matSortDirection="desc" (matSortChange)="sortData($event)">
    
        <!-- File Id Column -->
        <ng-container matColumnDef="customerFileId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>File Id </th>
        <td mat-cell *matCellDef="let element"> {{element.customerFileId}} </td>
        </ng-container>
    
        <!-- Customer Column -->
        <ng-container matColumnDef="customerName">
        <th mat-header-cell *matHeaderCellDef>Customer</th>
        <td mat-cell *matCellDef="let element" (click)="getTheLeadDetails(element)"><span id="statusInfo"> {{element.customerName}}</span> </td>
        </ng-container>
    
        <!-- ClientNo Column -->
        <ng-container matColumnDef="clientNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>ClientNo </th>
        <td mat-cell *matCellDef="let element"> {{element.clientNumber}} </td>
        </ng-container>
    
        <!-- Client Column -->
        <ng-container matColumnDef="clientName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Client </th>
        <td mat-cell *matCellDef="let element"> {{element.clientName}} </td>
        </ng-container>

        <!-- Supplier Column -->
        <ng-container matColumnDef="supplierName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Supplier </th>
            <td mat-cell *matCellDef="let element"> 
                <span>{{element.supplierName}} </span><br/>
                <span *ngIf="element.supplierRequestId" class="small-text">{{'ReqId '+element.supplierRequestId}}</span>
            </td>
        </ng-container>

        <!-- Entered By Column -->
        <ng-container matColumnDef="enteredBy" stickyEnd>
            <th mat-header-cell *matHeaderCellDef> Entered By </th>
            <td mat-cell *matCellDef="let element">
                 <span>{{element.enteredBy}} </span><br/>
                <span class="small-text">{{'On: '+element.enteredDate}}</span>
            </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div role="Paginator"  *ngIf="!isNoRecordsFound">
        <mat-paginator tabindex="-1" #paginator aria-label="Paginator" [length]="pageInfo.totalCount"
          [pageSize]="pageInfo.pageSize" [pageSizeOptions]="pageInfo.pageSizeOptions" (page)="onPagination($event)"></mat-paginator>
    </div>
</section>
<div role="NoRecordsFound" tabindex="0" *ngIf="isNoRecordsFound"
    class="invalidData center">
    <h3>No records found!</h3>
</div>