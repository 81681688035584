import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'supplierListFilter',
    pure: false
})

export class SupplierListFilterPipe implements PipeTransform {
    transform(items: any[], filter: Object): any {
        if (!items || !filter['initial'] || !filter['type']) {
            return items;
        }
        if (filter['type'] === 'state') {
            return items.filter(item => item.state.substring(0, 1) === filter['initial']);
        } else if (filter['type'] === 'city') {
            return items.filter(item => item.city.substring(0, 1) === filter['initial']);
        } else {
            return items;
        }
    }
}
