<ngx-spinner type="ball-spin-clockwise-fade" size="medium"></ngx-spinner>
<div class="cdk-visually-hidden" aria-live="polite" aria-atomic="true" [innerHTML]="announceStmt"></div>
<div class="cdk-visually-hidden" aria-live="polite" [innerHTML]="sortStatement" aria-atomic="true"></div>
<section class="content_table">
  <h1 class="totalSuppliers" attr.aria-label="{{ totalSuppliers }} Suppliers"> {{ totalSuppliers }} Suppliers <span class="material-icons add-supplier-icon" (click)="addNewSupplier()"> add_circle</span></h1>
  <br/>
  <div>   
    <!---Search box begins-->   
    <section class="search_table">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <span role="search" fxFlex="60%" fxLayoutAlign="start">
            <mat-form-field appearance="outline" class="search-within-table search-box">
              <mat-icon matPrefix>search</mat-icon>
              <input aria-atomic="true" matInput id="searchInput" #searchInput autocomplete="off" placeholder="Search within table for..." width="80%" fxFlex.sm="65"
               [(ngModel)]="filterText" (input)="applyFilter($event.target.value)">
              <button matTooltip="clear button" mat-button *ngIf="filterText" matSuffix mat-icon-button aria-label="Clear" (click)="resetList()">
                <mat-icon id="search_icon">close</mat-icon>
              </button>
            </mat-form-field>
            <span fxLayoutAlign="start center">
              <button id="filterTable" (click)="openFilterOptions()" aria-label="Filter-Table">
                <mat-icon matTooltip="Filter Table" matTooltipPosition="right" class="viewsetting-icon">filter_list</mat-icon>
              </button>
              <button id="selectColumns" (click)="openTableOptions()" aria-label="Select-Columns">
                <mat-icon matTooltip="Select columns" matTooltipPosition="right" class="viewsetting-icon">view_column</mat-icon>
              </button>
            </span>
        </span>        
      </div>
    </section> 
    <!--Search box ends-->
  </div>
  <br/>
  <div>
    <section class="mat-elevation-z8">
      <div *ngIf="suppliers.length > 0 && dataSource.filteredData.length > 0" style="overflow:auto;">
        <table mat-table [dataSource]="dataSource" aria-describedby="table-suppliers" matSort [matSortActive]="sortBy"
          matSortDirection="asc" (matSortChange)="sortData($event)">
          <!-- Supplier Name Column -->
          <ng-container matColumnDef="supplierName" sticky>
            <th id="" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Supplier Name">Suppier Name</th>
            <td mat-cell *matCellDef="let element" class="cLinks" 
            (click)="navigateToSupplierDetails(element, $event);return" for="statusInfo" tabindex="0">
              <a href="#" (click)="navigateToSupplierDetails(element, $event);" role="Suppliername" tabindex="0" class="text-as-link"
              id="statusInfo" [attr.aria-label]="element.fullName" aria-describedby="supplierData"
               [innerHTML]="(element.details.party.entityName) | highlightSearch: filterText"></a>
               </td>
          </ng-container>
          <!-- Supplier Legal Name Column -->
          <ng-container matColumnDef="supplierLegalName">
            <th id="" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Supplier Legal Name">Suppier Legal Name</th>
            <td mat-cell *matCellDef="let element">
              <span [innerHTML]="(element.details.party.preferredName ? element.details.party.preferredName : '-') | highlightSearch: filterText"></span>
                </td>
          </ng-container>
          <!-- Supplier Vendor # Column -->
          <ng-container matColumnDef="supplierVendorNo">
            <th id="" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Supplier Vendor #">Supplier Vendor #</th>
            <td mat-cell *matCellDef="let element">
              <span [innerHTML]="(element.details.vendorNumber ? element.details.vendorNumber : '-') | highlightSearch: filterText"></span>
                </td>
          </ng-container>
          <!-- Location Column -->
          <ng-container matColumnDef="location">
            <th id="" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Location">Location</th>
            <td mat-cell *matCellDef="let element">
              <span [innerHTML]="(element.details.party.contactMechanisms[0] ?
                                  ((element.details.party.contactMechanisms[0]?.city ? element.details.party.contactMechanisms[0].city : '') +
                                  (element.details.party.contactMechanisms[0]?.state ? ', ' + element.details.party.contactMechanisms[0].state : '')) :
                                  '-') | highlightSearch: filterText"></span>           
            </td>
          </ng-container>
          <!-- Contract Status column -->
          <ng-container matColumnDef="contractStatus">
            <th id="" mat-header-cell *matHeaderCellDef matTooltip="Contract Status">Contract Status</th>
            <td mat-cell *matCellDef="let element">
              <app-supplier-list-contract-status [serviceDetails]="element.details.serviceDetails"></app-supplier-list-contract-status>
            </td>
          </ng-container>
          <!-- Supplier Active column -->
          <ng-container matColumnDef="supplierActive">
            <th id="" mat-header-cell *matHeaderCellDef matTooltip="Supplier Active">Supplier Active</th>
            <td mat-cell *matCellDef="let element">
              <div class="supplier-status-container">
                <mat-label [ngClass]="!supplierActive(element) ? 'label-before-slide-active' : 'label-before-slide'">Off</mat-label>
                <mat-slide-toggle class="toggle" [checked]="supplierActive(element)" (change)="toggleActiveConfirm($event, element)" labelPosition="after"></mat-slide-toggle>
                <mat-label [ngClass]="supplierActive(element) ? 'label-after-slide-active' : 'label-after-slide'">On</mat-label>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
      <div role="Paginator" [hidden]="!(suppliers.length> 0 && dataSource.filteredData.length > 0)">
        <mat-paginator tabindex="-1" aria-label="Paginator" [length]="pageInfo.totalCount" [pageSize]="pageInfo.pageSize" [pageSizeOptions]="[5, 10, 20]"
          (page)="onPagination($event)"></mat-paginator>
      </div>
    </section>
  </div>
  <div role="NoRecordsFound" tabindex="0" [hidden]="suppliers.length > 0 && dataSource.filteredData.length > 0" class="invalidData">
    No records found
  </div>
</section>
