import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-supplier-details-edit',
  templateUrl: './supplier-details-edit.component.html',
  styleUrls: ['./supplier-details-edit.component.scss']
})
export class SupplierDetailsEditComponent implements OnInit, OnChanges {

  isModelView = false;

  @Input() partyDetails: any;

  partyDetailsForm: UntypedFormGroup;

  countyList: any[] = [
    {displayValue: 'United States of America', value: 'United States of America'},
    {displayValue: 'United Kingdom', value: 'UK'},
    {displayValue: 'India', value: 'IN'}
  ];

  stateList: any[] = [
    {displayValue: 'New York', value: 'NY'},
    {displayValue: 'New York', value: 'OH'},
  ];

  constructor(public dialogRef: MatDialogRef<SupplierDetailsEditComponent>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public dialogData: Object) { }

  ngOnInit() {
    this.createForm();
    if (this.dialogData && this.dialogData['isModelView']) {
      this.isModelView = true;
      this.partyDetails = this.dialogData['details'];
      this.partyDetailsForm.enable();
    }
    this.patchPartyForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.partyDetails) {
      this.patchPartyForm();
    }
  }

  patchPartyForm () {
    if (this.partyDetails && this.partyDetailsForm) {
      this.partyDetailsForm.get('vendorNumber').setValue(this.partyDetails.vendorNumber);
      this.partyDetailsForm.get('entityName').setValue(this.partyDetails.entityName);
      this.partyDetailsForm.get('preferredName').setValue(this.partyDetails.preferredName);
      this.partyDetailsForm.get('primaryLoactionDetails').patchValue(this.fetchLocationDetails());
    }
  }

  fetchLocationDetails() {
    const locationDetails = {
      country: '',
      streetAddress: '',
      city: '',
      state: '',
      zipcode: ''
    };
    if (this.partyDetails.contactMechanisms && this.partyDetails.contactMechanisms.length) {
      this.partyDetails.contactMechanisms.forEach(element => {
        if (element.contactType === 'address') {
          locationDetails.country = element.country;
          locationDetails.state = element.state;
          locationDetails.city = element.city;
          locationDetails.zipcode = element.postalCode;
          locationDetails.streetAddress = element.addressLines.toString();
        }
      });
    }

    return locationDetails;
  }

  getUpdatedDetails () {
    if (this.partyDetails) {
      const updatedValue = this.partyDetailsForm.getRawValue();
      this.partyDetails.vendorNumber = updatedValue.vendorNumber;
      this.partyDetails.entityName = updatedValue.entityName;
      this.partyDetails.preferredName = updatedValue.preferredName;

      if (this.partyDetails.contactMechanisms && this.partyDetails.contactMechanisms.length &&
         updatedValue.primaryLoactionDetails) {
        this.partyDetails.contactMechanisms.forEach(element => {
          if (element.contactType === 'address') {
            element.country = updatedValue.primaryLoactionDetails.country;
            element.state = updatedValue.primaryLoactionDetails.state;
            element.city = updatedValue.primaryLoactionDetails.city;
            element.postalCode = updatedValue.primaryLoactionDetails.zipcode;
            element.addressLines = updatedValue.primaryLoactionDetails.streetAddress;
          }
        });
      }
    }
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  saveChanges () {
    this.getUpdatedDetails();
    this.patchPartyForm();
    this.dialogRef.close(this.partyDetails);
  }

  createForm () {
    this.partyDetailsForm = this.fb.group({
      primaryLoactionDetails: this.fb.group({
        country: ['', Validators.required],
        streetAddress: ['', Validators.required],
        city: ['', Validators.required],
        state: ['', Validators.required],
        zipcode: ['', Validators.required]
      }),
      vendorNumber: new UntypedFormControl('', Validators.required),
      entityName: new UntypedFormControl('', Validators.required),
      preferredName: new UntypedFormControl('', Validators.required),
    });
    this.partyDetailsForm.disable();
  }
}
