<div class="filter-container" flexLayout fxLayout.xs="column">
  <div mat-dialog-title disableTypography className={styles.dialogTitle} fxLayout="row" fxLayoutAlign="space-between"
    fxLayoutGap="20px">
    <h1 class="dialog-title" mat-dialog-title title="Filter Table" role="heading" aria-level="1">Filter Table</h1>
    <a href="#" class="close-icon" (click)="onNoClick($event)" title="close">
      <mat-icon>close</mat-icon>
    </a>
  </div>
  <mat-dialog-content>
    <div class="filter-table-col">
      <div class="filter-scroll-div">
        <mat-grid-list [cols]="gridColumn" rowHeight="15em" gutterSize="1em">
          <mat-grid-tile>
            <span class="filter-heading">Supplier Location:</span>
            <div class="filter-location-1">
              <mat-checkbox class="filter-select-all"
                [checked]="(filter.matchedLocations.states.length + filter.matchedLocations.cities.length) > 0 
                && (filter.matchedLocations.states.length + filter.matchedLocations.cities.length) === filter.matchedLocationsSelected"
                [disabled]="(filter.matchedLocations.states.length + filter.matchedLocations.cities.length) === 0"
                (change)="toggleAllLocations($event)">
              </mat-checkbox>
                <div class="filter-search">
                  <input placeholder="City, State, or Country" (keyup)="searchTerm$.next($event.target.value)" [value]="searchInput">
                  <button type="button" (click)="clearInput()"><mat-icon>close</mat-icon></button>
                </div>
            </div>
            <div class="filter-location-2">
              <div class="filter-location-messages">
              <span *ngIf="(filter.matchedLocations.states.length + filter.matchedLocations.cities.length) === 0 && searchInput.length > 0">
                No results found
              </span>
              <span *ngIf="(filter.matchedLocations.states.length + filter.matchedLocations.cities.length) === 0 && searchInput.length === 0">
                Please Enter a City, State, or Country
              </span>
            </div>
              <div *ngFor="let initial of filter.matchedLocations.statesInitials">
                <span class="filter-title">States: {{initial}}</span>
                <div *ngFor="let state of filter.matchedLocations.states | supplierListFilter:{ type: 'state', initial: initial }">
                  <mat-checkbox [checked]="state.selected" (change)="changeState(state, $event)">{{state.state}}</mat-checkbox>
              </div>
            </div>
            <div *ngFor="let initial of filter.matchedLocations.citiesInitials">
              <span class="filter-title">Cities: {{initial}}</span>
              <div *ngFor="let city of filter.matchedLocations.cities | supplierListFilter:{ type: 'city', initial: initial }">
                <mat-checkbox [checked]="city.selected" (change)="changeCity(city, $event)">{{city.city}}{{city.state ? ', ' + city.state : ''}}</mat-checkbox>
            </div>
          </div>
            </div>
            <div class="filter-selected-count">
              <span>{{locationsSelected}}</span> Selected
              <a href="#" class="filter-clear-all" *ngIf="filter.matchedLocationsSelected > 0" title="clear all" (click)="clearAllLocations($event)">Clear All</a>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <span class="filter-heading">Contract Status:</span>
            <div class="filter-status">
              <div *ngFor="let contractStatus of filter.contractStatuses">
                <div class="filter-status-checkbox">
                  <mat-checkbox [checked]="contractStatus.selected" (change)="changeStatus(contractStatus, $event)">
                    {{contractStatus.status}}
                  </mat-checkbox>
                </div>
              </div>
            </div>
            <div class="filter-selected-count">
              <span>{{statusesSelected}}</span> Selected
              <a href="#" class="filter-clear-all" *ngIf="filter.contractStatusesSelected > 0" (click)="clearAllStatuses($event)" title="clear all">Clear All</a>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="statusBtn">
      <button mat-button color="primary" class="text-button" id="reset" (click)="cancel()">CANCEL</button>
      <button class="contained-button mat-button" id="save" (click)="save()">OK</button>
    </div>
  </mat-dialog-actions>
</div>
