import { NgModule, InjectionToken } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot } from '@angular/router';
import { LogoutComponent } from './core/components/logout/logout.component';
import { NonCompatibleComponent } from './core/components/non-compatible/non-compatible.component';
import { AuthGuard } from './core/components/guards/authGuard';
import { WorkOrderListComponent } from './core/components/work-orders/work-order-list/work-order-list.component';
import { QuoteRequestListComponent } from './core/components/quotes/quote-request-list/quote-request-list.component';
import { WorkOrdersDetailsComponent } from './core/components/work-orders/work-orders-details/work-orders-details.component';
import { QuoteRequestDetailComponent } from './core/components/quotes/quote-request-detail/quote-request-detail.component';
import { ExternRouteComponent } from './core/components/shared/extern-route/extern-route.component';
import { SupplierContactsListComponent } from './core/components/supplier-contacts/supplier-contacts-list/supplier-contacts-list.component';
import { AccountSettingsComponent } from './core/components/account-settings/account-settings.component';
import { HotelLeadsFormComponent } from './core/components/hotel-leads/hotel-leads-form/hotel-leads-form.component';
import { HotelLeadsListComponent } from './core/components/hotel-leads/hotel-leads-list/hotel-leads-list.component';
import { SupplierListComponent } from './core/components/suppliers/supplier-list/supplier-list.component';
import { SupplierInfoComponent } from './core/components/suppliers/supplier-info/supplier-info.component';

const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');
const supplierContactCapability = 'Supplier Portal Access';
const supplierContactCapabilityAll = 'Supplier Portal Access';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'quotes-request',
    pathMatch: 'full'
  },
  {
    path: 'quotes-request',
    component: QuoteRequestListComponent,
    data: {
      capabilities: [supplierContactCapability,
        supplierContactCapabilityAll]
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'work-orders',
    component: WorkOrderListComponent,
    data: {
      capabilities: [supplierContactCapability,
        supplierContactCapabilityAll]
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'hotel-leads/entryForm',
    component: HotelLeadsFormComponent,
    data: {
      capabilities: [supplierContactCapability,
        supplierContactCapabilityAll]
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'hotel-leads/lists',
    component: HotelLeadsListComponent,
    data: {
      capabilities: [supplierContactCapability,
        supplierContactCapabilityAll]
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'supplier-contacts',
    component: SupplierContactsListComponent,
    data: {
      capabilities: ['Supplier Contact List']
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'suppliers',
    component: SupplierListComponent,
    data: {
      capabilities: [supplierContactCapability,
        supplierContactCapabilityAll]
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'supplier/detail',
    component: SupplierInfoComponent,
    data: {
      capabilities: [supplierContactCapability,
        supplierContactCapabilityAll]
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'quote-request/detail',
    component: QuoteRequestDetailComponent,
    data: {
      capabilities: [supplierContactCapability,
        supplierContactCapabilityAll]
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'work-order/detail',
    component: WorkOrdersDetailsComponent,
    data: {
      capabilities: [supplierContactCapability,
        supplierContactCapabilityAll]
    },
    canActivate: [AuthGuard]
  },
    {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'externalRedirect',
    resolve: {
      url: externalUrlProvider
    },
    component: ExternRouteComponent
  },
  {
    path: 'not-supported',
    component: NonCompatibleComponent
  },
  {
    path: 'account-settings',
    component: AccountSettingsComponent,
    data: {
      capabilities: [supplierContactCapability,
        supplierContactCapabilityAll]
    },
    canActivate: [AuthGuard]
  },
];

@NgModule({
  providers: [
    {
      provide: externalUrlProvider,
      useValue: (route: ActivatedRouteSnapshot) => {
        const externalURL = route.paramMap.get('externalUrl');
        window.location.replace(externalURL);
      }
    }
  ],
  imports: [RouterModule.forRoot(routes, { enableTracing: false, onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
