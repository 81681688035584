import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import {
  HashLocationStrategy,
  LocationStrategy,
  DatePipe
} from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { OAuthModule } from 'angular-oauth2-oidc';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule } from '@angular/common/http';
import { HighlightSearchPipe } from './core/pipes/highlight-search.pipe';
import { SupplierListFilterPipe } from './core/pipes/supplier-list-filter.pipe';
import { SessionPopUpComponent } from './core/components/session-pop-up/session-pop-up.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CookieService } from 'ngx-cookie-service';
import { LogoutComponent } from './core/components/logout/logout.component';
import { SessionTimeoutComponent } from './core/components/session-timeout/session-timeout.component';
import { NonCompatibleComponent } from './core/components/non-compatible/non-compatible.component';
import { FooterComponent } from './core/components/shared/footer/footer.component';
import { CandidateContactInfoComponent } from './core/components/candidate-contact-info/candidate-contact-info.component';
import { CandidateInfoComponent } from './core/components/candidate-info/candidate-info.component';
import { CandidateMoveInfoComponent } from './core/components/candidate-move-info/candidate-move-info.component';
import { WorkOrderListComponent } from './core/components/work-orders/work-order-list/work-order-list.component';
import { OverlayTooltipComponent } from './core/components/shared/overlay-tooltip/overlay-tooltip.component';
import { ExternRouteComponent } from './core/components/shared/extern-route/extern-route.component';
import { InputPhoneFormatDirective } from './core/directives/input-phone-format.directive';
import {
  CandidateDepartureHomeDetailComponent
} from './core/components/candidate-departure-home-detail/candidate-departure-home-detail.component';
import { MaterialModule } from './material/material.module';
import { AgentInformationComponent } from './core/components/work-orders/work-orders-details/agent-information/agent-information.component';
import { CommonModule } from '@angular/common';
import { FilterModalComponent } from './core/components/filter-modal/filter-modal.component';
import { QuoteRequestListComponent } from './core/components/quotes/quote-request-list/quote-request-list.component';
import { QuoteRequestDetailComponent } from './core/components/quotes/quote-request-detail/quote-request-detail.component';
import { WorkOrdersDetailsComponent } from './core/components/work-orders/work-orders-details/work-orders-details.component';
import { QuoteResponseComponent } from './core/components/quotes/quote-response/quote-response.component';
import { EditKeysComponent } from './core/components/edit-keys/edit-keys.component';
import {
  CandidateDestinationHomeDetailComponent
} from './core/components/candidate-destination-home-detail/candidate-destination-home-detail.component';
import {
  SupplierContactsListComponent
} from './core/components/supplier-contacts/supplier-contacts-list/supplier-contacts-list.component';
import { EditSupplierContactComponent } from './core/components/supplier-contacts/edit-supplier-contact/edit-supplier-contact.component';
import {
  InactiveSupplierContactsComponent
} from './core/components/supplier-contacts/inactive-supplier-contacts/inactive-supplier-contacts.component';
import {
  QuoteRequestListColumnPopUpComponent
} from './core/components/quotes/quote-request-list/quote-request-list-column-pop-up/quote-request-list-column-pop-up.component';
import { AmountFormatDirective } from './core/directives/amount-format.directive';
import { CandidateQuoteInfoComponent } from './core/components/candidate-quote-info/candidate-quote-info.component';
import {
  WorkOrderListColumnPopUpComponent
} from './core/components/work-orders/work-order-list/work-order-list-column-pop-up/work-order-list-column-pop-up.component';
import { CandidateAgentInfoComponent } from './core/components/candidate-agent-info/candidate-agent-info.component';
import { AccountSettingsComponent } from './core/components/account-settings/account-settings.component';
import {SensitiveInfoMaskComponent} from './core/components/sensitive-info-mask/sensitive-info-mask.component';
import { MatPaginatorIntlEng } from './material/mat-paginator-intl';
import { HotelLeadsFormComponent } from './core/components/hotel-leads/hotel-leads-form/hotel-leads-form.component';
import { HotelLeadsListComponent } from './core/components/hotel-leads/hotel-leads-list/hotel-leads-list.component';
import { SupplierListComponent } from './core/components/suppliers/supplier-list/supplier-list.component';
import {
  SupplierListColumnPopUpComponent
} from './core/components/suppliers/supplier-list/supplier-list-column-pop-up/supplier-list-column-pop-up.component';
import {
  SupplierListContractStatusComponent
} from './core/components/suppliers/supplier-list-contract-status/supplier-list-contract-status.component';
import {
  SupplierListFilterPopUpComponent
} from './core/components/suppliers/supplier-list/supplier-list-filter-pop-up/supplier-list-filter-pop-up.component';
import {
  SupplierInfoComponent
} from './core/components/suppliers/supplier-info/supplier-info.component';
import {
  SupplierDetailsEditComponent
} from './core/components/suppliers/supplier-info/supplier-details-edit/supplier-details-edit.component';
import {
  EditContractTerritoryPopupComponent
} from './core/components/suppliers/supplier-info/edit-contract-territory-popup/edit-contract-territory-popup.component';
import {
  AddContractSiteIdPopupComponent
} from './core/components/suppliers/supplier-info/add-contract-site-id-popup/add-contract-site-id-popup.component';
import { AddContractPopupComponent } from './core/components/suppliers/supplier-info/add-contract-popup/add-contract-popup.component';
import {
  SupplierListDeactivatePopUpComponent
} from './core/components/suppliers/supplier-list/supplier-list-deactivate-pop-up/supplier-list-deactivate-pop-up.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatStepperModule } from '@angular/material/stepper';
import { MatRadioModule } from '@angular/material/radio';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';
import { AppConfigService } from './core/services/app-config.service';

@NgModule({
  declarations: [
    AppComponent,
    SessionPopUpComponent,
    HighlightSearchPipe,
    SupplierListFilterPipe,
    LogoutComponent, SessionTimeoutComponent,
    NonCompatibleComponent,
    FooterComponent,
    CandidateContactInfoComponent,
    CandidateInfoComponent,
    CandidateMoveInfoComponent,
    CandidateDepartureHomeDetailComponent,
    WorkOrderListComponent,
    ExternRouteComponent,
    OverlayTooltipComponent,
    InputPhoneFormatDirective,
    FilterModalComponent,
    QuoteRequestListComponent,
    QuoteRequestDetailComponent,
    WorkOrdersDetailsComponent,
    QuoteResponseComponent,
    EditKeysComponent,
    AgentInformationComponent,
    CandidateDestinationHomeDetailComponent,
    SupplierContactsListComponent,
    EditSupplierContactComponent,
    InactiveSupplierContactsComponent,
    QuoteRequestListColumnPopUpComponent,
    AmountFormatDirective,
    CandidateQuoteInfoComponent,
    WorkOrderListColumnPopUpComponent,
    CandidateAgentInfoComponent,
    AccountSettingsComponent,
    SensitiveInfoMaskComponent,
    HotelLeadsFormComponent,
    HotelLeadsListComponent,
    SupplierListComponent,
    SupplierListColumnPopUpComponent,
    SupplierListContractStatusComponent,
    SupplierListFilterPopUpComponent,
    SupplierInfoComponent,
    SupplierDetailsEditComponent,
    EditContractTerritoryPopupComponent,
    AddContractSiteIdPopupComponent,
    AddContractPopupComponent,
    SupplierListDeactivatePopUpComponent
  ],
  imports: [
    OAuthModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatButtonModule,
    MatSidenavModule,
    MatExpansionModule,
    MatIconModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatDialogModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatToolbarModule,
    MatListModule,
    MatStepperModule,
    MatRadioModule,
    MatGridListModule,
    MatDatepickerModule,
    MatCardModule,
    FormsModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatTabsModule,
    MatSortModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatDividerModule,
    MaterialModule,
    MatSlideToggleModule,
    NgxSpinnerModule,
    AppRoutingModule, // I must be last!! https://angular.io/guide/router#module-import-order-matters
    ServiceWorkerModule.register(
      'ngsw-worker.js'
    ) // I must be last!! https://angular.io/guide/router#module-import-order-matters
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlEng},
    HighlightSearchPipe,
    DatePipe,
    CookieService,
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
    {
      provide: SwRegistrationOptions,
      useFactory: (config: AppConfigService) => ({enabled: config.getConfig('environment') !== 'developer'}),
      deps: [AppConfigService]
    },
  ],
  bootstrap: [ AppComponent ]
})

export class AppModule { }
