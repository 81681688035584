<div fxLayout="row wrap">
    <h4 fxFlex="70" class="header-label">{{isEditView ? 'Edit Site ID' : 'Add Site ID'}}</h4>
    <div fxFlex="30" fxLayoutAlign="end start">
        <mat-icon class="close-icon" (click)="onCloseClick()">close</mat-icon>
    </div>
</div>
<div fxLayout="row wrap" *ngIf="siteIdFormGroup" fxLayoutGap="5%" [formGroup]="siteIdFormGroup">
    <div fxFlex="45">
        <mat-form-field>
            <mat-label>Oracle Site ID</mat-label>
            <input class="businessUnit" matInput formControlName="siteId" >
        </mat-form-field>
    </div>
    <div fxFlex="45">
        <mat-form-field >
            <mat-label>Country</mat-label>
            <mat-select formControlName="country">
              <mat-option *ngFor="let country of countryList" [value]="country.value">{{country.displayValue}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div fxFlex="100">
        <mat-form-field>
            <mat-label>Street Address</mat-label>
            <input class="businessUnit" matInput  formControlName="streetAddress">
        </mat-form-field>
    </div>
    <div fxFlex="45">
        <mat-form-field>
            <mat-label>City</mat-label>
            <input class="businessUnit" matInput  formControlName="city">
        </mat-form-field>
    </div>
    <div fxFlex="20">
        <mat-form-field >
            <mat-label>State</mat-label>
            <mat-select formControlName="state">
              <mat-option *ngFor="let state of statesList" [value]="state.value">{{state.displayValue}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div fxFlex="25">
        <mat-form-field>
            <mat-label>Zip Code</mat-label>
            <input class="businessUnit" matInput  formControlName="postalCode">
        </mat-form-field>
    </div>
</div>
<mat-dialog-actions align="end" >
    <button mat-button color="primary" (click)="onCloseClick()" >CANCEL</button>
    <button mat-raised-button color="primary" [disabled]="isFormInvalid()" (click)="saveChanges()" class="save_btn" [ngClass]="{'save_btn_disabled':isFormInvalid()}">Save</button>
</mat-dialog-actions>