<div flexLayout fxLayout.xs="column">
  <div mat-dialog-title disableTypography className={styles.dialogTitle} fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
    <h1 class="supplier-dialogtitle" mat-dialog-title title="Deactivate Supplier" role="heading" aria-level="1">Deactivate Supplier</h1>
    <a href="#" class="close-icon" (click)="onNoClick($event)" title="close">
      <mat-icon>close</mat-icon>
    </a>
  </div>
  <mat-dialog-content>
    <p>You are about to Deactivate <strong>{{supplierName}}</strong>.</p>
    <p>Any active contracts they currently have will be automatically deactivated and they will be removed from this list.</p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="statusBtn">
      <button class="text-button" mat-button id="cancel" mat-button color="primary" (click)="onCancel()">CANCEL</button>
      <button class="contained-button mat-button" mat-button id="deactivate" (click)="onDeactivate()">Deactivate Supplier</button>
    </div>
  </mat-dialog-actions>
</div>
