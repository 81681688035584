import { Component, OnInit, AfterViewInit, ChangeDetectorRef, Inject, EventEmitter, Output, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationsService } from '../../../../core/services/notifications.service';
import { DateAdapterService } from '../../../../core/services/date-adapter.service';
import { APP_DATE_FORMATS, apiErrorMessage } from '../../../../core/models/constants';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { LoggerService } from '../../../../core/services/logger.service';
import { LoggedInUserService } from '../../../../core/services/loggedin-user-service';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { QuoteRequestService } from 'src/app/core/services/quote-request.service';
import { QuoteResponse } from '../../../models/quote-response.model';
import { validateBasis } from '@angular/flex-layout';
import { Router } from '@angular/router';
import { ValidateAmount } from 'src/app/core/validators/amount.validator';
import { MatSnackBar } from '@angular/material/snack-bar';
export const fixDialogFocus = (event, dialogRef: MatDialogRef<any>) => {
    /** Global event is deprecated have to pass it through evenet listner*/

    if (event && event.currentTarget && (event.currentTarget).blur) {
        const initiator: any = event.currentTarget;

        dialogRef.afterClosed().subscribe(() => {
            setTimeout(() => {
                try { initiator.blur(); } catch {}
            }, 0);
        });
    }
};
@Component({
  selector: 'app-quote-response',
  templateUrl: './quote-response.component.html',
  styleUrls: ['./quote-response.component.scss']
})
export class QuoteResponseComponent implements OnInit, AfterViewInit, OnDestroy {
  quoteResponseData: QuoteResponse = {
    orderRequestId: '',
    quoteRequestId: '',
    referenceNumber: '',
    bidDate: new Date (''),
    bidAmount: '',
    bidAmountCurrency: '',
    bidAmountStorage: '',
    bidAmountStorageCurrency: '',
    estimatedWeight: 0,
    estimatedWeightUnit: '',
    estimatedDistance: 0,
    estimatedDistanceUnit: '',
    moveType: '',
    affiliate: '',
    nonDiscountableThirdPartyServices: 0,
    nonDiscountableThirdPartyServicesCurrency: ''
  };
  days = 30;
  errorMessage = 'We are unable to process your request at this time. Please try again later.';
  MoveType: any = ['Micro', 'Regular'];
  Affiliate: any = ['Ace Relocation'];
  selected = true;
  select = true;
  /**flag to check the transferee authorization */
  isTransfereeAuthorized: boolean;
  editCandidateForm: UntypedFormGroup;
  /**property to store Job Start Date */
  jobStartOn: Date;
  /**property to store invoice details */
  invoiceDetails: any;
  /**property for min Date */
  minStartDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate());
  /** Stores the client contact ID */
  clientContactId: string;
  matExpansionHeaderHeight = '0px';
  matCollapseHeaderHeight = '40px';
  isStorageOpen = false;
  isNonDiscountPanelOpen = false;
  isNonDiscountPanelinValid = false;
  eventListnerFn: (event: any) => void;
  /** Subscription prop for unsubscribing services */
  private readonly subscription: Subscription = new Subscription();

  /**
   * It Emits the data to the parent component
   */
  @Output() submitQuoteResponses = new EventEmitter<QuoteResponse>();
  /**
   * base constructor
   * @param data data received from calling component
   * @param dialogRef - property for mat dialog reference
   * @param notificationsService - snackbar service
   * @param snackBar to display snack bar
   * @param spinner - ngx spinner service
   */

  /**variable to store electronicPaymentInstructions */
  electronicPaymentInstructions: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<QuoteResponseComponent>,
    private readonly notificationsService: NotificationsService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly snackBar: MatSnackBar,
    private readonly spinner: NgxSpinnerService,
    private readonly quoteRequestService: QuoteRequestService,
    private readonly Logger: LoggerService,
    private readonly loggedInUserService: LoggedInUserService,
    private router: Router,
    private cdRef:ChangeDetectorRef
  ) {
    this.eventListnerFn = function(event) {
      fixDialogFocus(event, this.dialogRef);
    };
   }

  /** function to be executed on init */
  ngOnInit() {
    document.addEventListener('blur', this.eventListnerFn);
    this.editCandidateForm = this.formBuilder.group({
      reference: ['', Validators.required],
      bidAmount: ['', [Validators.required, Validators.min(1), ValidateAmount]],
      bidAmountStorage: [''],
      estimatedWeight: ['', [Validators.required, Validators.min(1), ValidateAmount]],
      estimatedDistance: ['', [Validators.required, Validators.min(1), ValidateAmount]],
      moveType: ['Regular'],
      affiliate: ['', Validators.required],
      bidStorageDays: [{value: '', disabled: true}],
      nonDiscountableThirdPartyServices: [''],
      nonDiscountableOthers: ['']
    });
    const storageCtrl = this.editCandidateForm.get('bidAmountStorage');
    storageCtrl.valueChanges.subscribe((data: string) => {
      const storageDaysCtrl = this.editCandidateForm.get('bidStorageDays');
      if (data.length === 0) {
        storageDaysCtrl.disable();
        this.setValidatorForStorageDays();
      } else {
        if (Number(storageCtrl.value) > 0) {
          storageDaysCtrl.enable();
          this.setValidatorForStorageDays(true);
        } else {
          storageDaysCtrl.disable();
          this.setValidatorForStorageDays();
        }
      }
    });
    this.subscribeValueChangeFornonDiscountableOthers();
    this.subscribeValueChangeFornonDiscountableThirdPartyServices();
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }

  subscribeValueChangeFornonDiscountableOthers() {
    const nonDiscountableOthersCtrl = this.editCandidateForm.get('nonDiscountableOthers');
    nonDiscountableOthersCtrl.valueChanges.subscribe((data: string) => {
      this.setNonDiscountPanelValid(true);
    });
  }

  subscribeValueChangeFornonDiscountableThirdPartyServices() {
    const nonDiscountableServicesCtrl = this.editCandidateForm.get('nonDiscountableThirdPartyServices');
    nonDiscountableServicesCtrl.valueChanges.subscribe((data: string) => {
      this.setNonDiscountPanelValid(true);
    });
  }

  /**
   * Closing the dialog box
   */
  close(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.dialogRef.close(true);
  }

  /** function to be called if Authorize Tranfer button is clicked */
  submitQuoteResponse() {
    // event.preventDefault();  event is deprecated
    // event.stopPropagation();
    this.quoteResponseData = {
      orderRequestId: String(this.data.orderRequestId),
      quoteRequestId: String(this.data.quoteRequestId),
      referenceNumber: String(this.editCandidateForm.value.reference),
      bidDate: new Date(),
      bidAmount: String(this.editCandidateForm.value.bidAmount),
      bidAmountCurrency: 'USD',
      estimatedWeight: Number(this.editCandidateForm.value.estimatedWeight),
      estimatedWeightUnit: 'lbs',
      estimatedDistance: Number(this.editCandidateForm.value.estimatedDistance),
      estimatedDistanceUnit: 'mi',
      moveType: (this.editCandidateForm.value.moveType).toLowerCase()
    };
    if (Number(this.editCandidateForm.value.bidStorageDays) > 0) {
      this.quoteResponseData.daysInStorage = Number(this.editCandidateForm.value.bidStorageDays);
    }
    if (Number(this.editCandidateForm.value.bidAmountStorage) > 0) {
      this.quoteResponseData.bidAmountStorage = String(this.editCandidateForm.value.bidAmountStorage);
      this.quoteResponseData.bidAmountStorageCurrency = 'USD';
    }

    if (Number(this.editCandidateForm.value.nonDiscountableThirdPartyServices) > 0) {
      this.quoteResponseData.nonDiscountableThirdPartyServices = Number(this.editCandidateForm.value.nonDiscountableThirdPartyServices);
      this.quoteResponseData.nonDiscountableThirdPartyServicesCurrency = 'USD';
    }

    if (Number(this.editCandidateForm.value.nonDiscountableOthers) > 0) {
      this.quoteResponseData.nonDiscountableOthers = Number(this.editCandidateForm.value.nonDiscountableOthers);
      this.quoteResponseData.nonDiscountableOthersCurrency = 'USD';
    }
    this.dialogRef.close(true);
    this.submitQuoteResponses.emit( this.quoteResponseData);
  }

  quoteResponse(response) {
    this.spinner.hide();
    if (response !== null) {
      this.flashAndCloseDialog(response.message);
    } else {
      this.dialogRef.close(true);
      this.notificationsService.flashNotification(
        'error',
        this.errorMessage,
        true,
        'dismiss'
      );
      this.router.navigate(['/quotes-request']);
    }
  }

  openStorage() {
    this.setValidatorForStorageCost(true);
    this.toggleAccordionPanel('isStorageOpen');
  }

  setNonDiscountPanelValid(isOpened: boolean) {
    if (isOpened) {
      if ((this.editCandidateForm.get('nonDiscountableOthers').value.length === 0) &&
      (this.editCandidateForm.get('nonDiscountableThirdPartyServices').value.length === 0)) {
        this.isNonDiscountPanelinValid = true;
      } else {
        this.isNonDiscountPanelinValid = false;
      }
    } else {
      this.isNonDiscountPanelinValid = false;
    }
  }

  openNonDiscountPanel() {
    this.setValidatorForNonDiscountableServices(true, 'nonDiscountableOthers');
    this.setValidatorForNonDiscountableServices(true, 'nonDiscountableThirdPartyServices');
    this.toggleAccordionPanel('isNonDiscountPanelOpen');
    this.setNonDiscountPanelValid(true);
  }

  toggleAccordionPanel(toggleElem: string) {
    this[toggleElem] = !this[toggleElem];
  }

  setValidatorForStorageDays(isApply: boolean = false) {
    const storageDaysCtrl = this.editCandidateForm.get('bidStorageDays');
    if (isApply) {
      storageDaysCtrl.setValidators([Validators.required, Validators.min(1), ValidateAmount]);
    } else {
      storageDaysCtrl.clearValidators();
      storageDaysCtrl.markAsUntouched();
    }
    storageDaysCtrl.updateValueAndValidity();
  }

  setValidatorForStorageCost(isApply: boolean = false) {
    const storageAmountCtrl = this.editCandidateForm.get('bidAmountStorage');
    if (isApply) {
      storageAmountCtrl.setValidators([Validators.required, Validators.min(1), ValidateAmount]);
    } else {
      storageAmountCtrl.clearValidators();
      storageAmountCtrl.markAsUntouched();
    }
    storageAmountCtrl.updateValueAndValidity();
  }

  setValidatorForNonDiscountableServices(isApply: boolean = false, elem: string) {
    const nonDiscountableElemCtrl = this.editCandidateForm.get(elem);
    if (isApply) {
      nonDiscountableElemCtrl.setValidators([Validators.min(1), ValidateAmount]);
    } else {
      nonDiscountableElemCtrl.clearValidators();
      nonDiscountableElemCtrl.markAsUntouched();
    }
    nonDiscountableElemCtrl.updateValueAndValidity();
  }

  closeStorage() {
    this.setValidatorForStorageCost();
    this.setValidatorForStorageDays();
    const storageAmountCtrl = this.editCandidateForm.get('bidAmountStorage');
    storageAmountCtrl.setValue('');
    const storageDaysCtrl = this.editCandidateForm.get('bidStorageDays');
    storageDaysCtrl.setValue('');
  }

  closeNonDiscountPanel() {
    this.setValidatorForNonDiscountableServices(false, 'nonDiscountableThirdPartyServices');
    this.setValidatorForNonDiscountableServices(false, 'nonDiscountableOthers');
    const nonDiscountableThirdPartyServicesCtrl = this.editCandidateForm.get('nonDiscountableThirdPartyServices');
    nonDiscountableThirdPartyServicesCtrl.setValue('');
    const nonDiscountableOthersCtrl = this.editCandidateForm.get('nonDiscountableOthers');
    nonDiscountableOthersCtrl.setValue('');
    this.setNonDiscountPanelValid(false);
  }

  /**
   * function to flash message and close dialog
   * @param response string
   */
  flashAndCloseDialog(message: string) {
    this.dialogRef.close(true);
    this.notificationsService.flashNotification(
      'success',
      'Quote response submitted successfully!',
      true,
      'dismiss'
    );
    this.router.navigate(['/quotes-request']);
  }

  /**
   * destroys the object
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
    document.removeEventListener('blur', this.eventListnerFn);
  }

}
