import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-add-contract-popup',
  templateUrl: './add-contract-popup.component.html',
  styleUrls: ['./add-contract-popup.component.scss']
})
export class AddContractPopupComponent implements OnInit {

  contractServiceTypes: any[] = [
    {
      displayValue: 'HHG Provide',
      value: 'HHG'
    }
  ];

  affiliateList: any[] = [{
    displayValue: 'Primary',
    value: 'Primary'
  }];

  affiliateRelationsList: any[] = [
    {
      displayValue: 'Primary',
      value: 'Primary'
    },
    {
      displayValue: 'Affiliate',
      value: 'Affiliate'
    }
  ];
  contractFormGroup: UntypedFormGroup;
  isEditView: boolean;

  constructor(public dialogRef: MatDialogRef<AddContractPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: UntypedFormGroup) { }

  ngOnInit() {
    if (this.dialogData) {
      console.log(this.dialogData);
      this.contractFormGroup = this.getContractGroupFrom();
      this.contractFormGroup.patchValue(this.dialogData.getRawValue());
      console.log(this.contractFormGroup.valid);
      if (this.contractFormGroup.get('_id') && this.contractFormGroup.get('_id').value) {
        this.contractFormGroup.disable();
        this.contractFormGroup.get('email').enable();
        this.isEditView = true;
      } else {
        this.contractFormGroup.enable();
      }
    }
  }

  onCloseClick () {
    this.dialogRef.close();
  }

  onCanceleClick () {
    this.contractFormGroup.disable();
    this.dialogRef.close();
  }

  saveChanges () {
    this.contractFormGroup.disable();
    this.dialogRef.close(this.contractFormGroup);
  }

  getContractGroupFrom () {
    const formGroup =  new UntypedFormGroup({
      _id: new UntypedFormControl('', Validators.required),
      kind: new UntypedFormControl(''),
      description: new UntypedFormControl(''),
      effectiveFrom: new UntypedFormControl(''),
      effectiveTo: new UntypedFormControl(''),
      __v: new UntypedFormControl(''),
      createdAt: new UntypedFormControl('') ,
      updatedAt: new UntypedFormControl(''),
      status: new UntypedFormControl(''),
      affiliateRelation: new UntypedFormControl(''),
      affiliateOf: new UntypedFormControl(''),
      affiliateId: new UntypedFormControl(''),
      serviceType: new UntypedFormControl(''),
      email : new UntypedFormControl(''),
      territoryServiceDetails : new UntypedFormArray([]),
      oracleSiteIdDetails : new UntypedFormArray([]),
    });

    return formGroup;
  }

  isFormInvalid () {
    return !this.contractFormGroup.get('serviceType').value || !this.contractFormGroup.get('effectiveFrom').value ||
     !this.contractFormGroup.get('affiliateRelation').value || !this.contractFormGroup.get('affiliateOf').value ||
     !this.contractFormGroup.get('affiliateId').value;
  }

}
