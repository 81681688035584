import { Injectable } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseClientService } from './base-client.service';
import { SupplierListItem } from '../models/supplier.model';
import { urlType } from '../models/urlType';

@Injectable({
  providedIn: 'root'
})
export class SuppliersService {

  constructor(private readonly baseClientService: BaseClientService) { }

  getSupplierLocations() {
    const locations = [
      { 'country': 'France', 'state': null, 'city': 'Neuilly-sur-Seine', 'selected': false },
      { 'country': 'United States of America', 'state': 'OH', 'city': 'Cleveland', 'selected': false },
      { 'country': 'United States', 'state': 'Connecticut', 'city': 'Danbury', 'selected': false },
      { 'country': 'United States', 'state': 'Connecticut', 'city': 'Monroe', 'selected': false },
    ];
    return of(locations);
  }

  getAllSuppliers(filterText?: string, skip?: number,  limit?: number, sortBy?: string, sortDir?: string): Observable<any> {
    // TODO: Need API to return total number of suppliers regardless of skip / options for paginator to work
    const params = {
      // filterText,
      // skip,
      // limit,
      // sortBy,
      // sortDir
    };
    return this.baseClientService.get<SupplierListItem>('/supplier/details', 'getting supplier list', urlType.candidateAPI, params).pipe(
      map(response => response.body)
    );
  }

/**
 *
 * Currently we have implemented Static screen as APIs are not implemented yet
 * So All the variable values and function logics has mocked data.
 * We can do modify them once we get actual API response.
 *
*/

  getSupplierDetails (legalType?, entityName?, preferredName?) {

    return {
      'details': {
        '_id': '61979b95e67ce03f7c3bbd25',
        'serviceDetails': [
          {
            'deliveryConfigurations': [
              {
                'region': '/US',
                'regulation': 'Regulated',
                'tariff': 'Cartus Rate Schedule',
                'commissions': [
                  {
                    'category': 'Micro Move',
                    'percentage': 5.5,
                    'effectiveFrom': '2020-07-09T18:25:43.511Z',
                    'effectiveTo': '2021-07-09T18:25:43.511Z'
                  }
                ],
                'discounts': [
                  {
                    'category': 'Standard Move',
                    'percentage': 24.6,
                    'effectiveFrom': '2022-07-09T18:25:43.511Z',
                    'effectiveTo': '2023-07-09T18:25:43.511Z'
                  }
                ]
              }
            ],
            '_id': '611e4f0f4d8f0300230d0818',
            'serviceReference': 'HHG',
            'contractId': '611e4784970d5d002c6e2615',
            'serviceType': 'territory',
            'territory': 'All states',
            'notificationEmailId': '5f2c2924cdcfa0000817c096',
            'updatedAt': '2021-08-19T12:31:11.943Z',
            'createdAt': '2021-08-19T12:31:11.943Z'
          },
          {
            'deliveryConfigurations': [
              {
                'siteId': '4424987234',
                'country': 'US',
                'streetAddress': '1023 Park Ave',
                'city': 'New york',
                'state': 'NY',
                'postalCode': '10019',
                'status': 'Active'
              },
              {
                'siteId': '44243445',
                'country': 'US',
                'streetAddress': '1024 Park Ave',
                'city': 'New york',
                'state': 'NY',
                'postalCode': '100333',
                'status': 'Active'
              }
            ],
            '_id': '611e4f0f4d8f0300230d0818',
            'serviceReference': 'HHG',
            'contractId': '611e4784970d5d002c6e2615',
            'serviceType': 'oracleSiteId',
            'notificationEmailId': '5f2c2924cdcfa0000817c096',
            'updatedAt': '2021-08-19T12:31:11.943Z',
            'createdAt': '2021-08-19T12:31:11.943Z'
          }
        ],
        'party': {
          'partyId': '61979b95e67ce03f7c3bbd23',
          'vendorNumber': 'E3CS001',
          'partyType': 'organization',
          'organizationKind': 'legal',
          'legalType': legalType ||  'parent',
          'status': 'Active',
          'entityName': entityName || 'Dwellworks, LLC',
          'preferredName': preferredName || 'Dwellworks Living',
          'contactMechanisms': [
            {
              '_id': '61979b94e67ce03f7c3bbd21',
              'contactType': 'address',
              'usageType': 'business',
              'isPreferred': true,
              'addressLines': [
                '1317 Euclid Ave'
              ],
              'city': 'Cleveland',
              'state': 'OH',
              'country': 'United States of America',
              'postalCode': '44115',
              '__v': 0,
              'createdAt': '2021-11-19T12:41:56.414Z',
              'updatedAt': '2021-11-19T12:41:56.414Z'
            },
            {
              '_id': '61979b94e67ce03f7c3bbd22',
              'textingAvailable': false,
              'isPreferred': true,
              'contactType': 'phone',
              'usageType': 'business',
              'number': '2166824200',
              'countryDialingCode': 1,
              '__v': 0,
              'createdAt': '2021-11-19T12:41:56.600Z',
              'updatedAt': '2021-11-19T12:41:56.600Z'
            }
          ],
          'roles': [
            {
              'roleId': '5dd7fc80589d7e5a120c6ec0',
              'fromDate': '2021-11-19T12:41:57.380Z',
              '_id': '5dd7fc80589d7e5a120c6ec0',
              'name': 'supplier',
              'description': 'Supplier',
              'associatedParty': '5dcf100073f701819e97d883',
              '__v': 0
            }
          ],
          '__v': 0,
          'createdAt': '2021-11-19T12:41:57.380Z',
          'updatedAt': '2021-11-19T12:41:57.380Z'
        },
        'supplierContractData': [
          {
            '_id': '611e4784970d5d002c6e2615',
            'kind': 'supplier',
            'involvedParties': [
              {
                'partyId': '61979b95e67ce03f7c3bbd23',
                'descriptor': 'Dwellworks, LLC'
              },
              {
                'partyId': '5dcf100073f701819e97d883',
                'descriptor': 'Cartus Corporation'
              }
            ],
            'description': 'Temporary Living Agreement',
            'effectiveFrom': '2021-11-19T12:41:57.625Z',
            'effectiveTo': '2031-11-19T12:41:57.625Z',
            '__v': 0,
            'createdAt': '2021-11-19T12:41:57.626Z',
            'updatedAt': '2021-11-19T12:41:57.626Z',
            'status': 'Active',
            'affiliateRelation': 'Affiliate',
            'affiliateOf': 'Primary',
            'affiliateId': '#abc123',
            'serviceType': 'HHG'
          }
        ]
      },
      'contractSummary': {
        'Active': 1
      }
    };
  }

}
