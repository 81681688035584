<div class="container" fxFlex="100%" fxLayout="row" fxLayout.xs="column" fxLayoutGap="32px" fxLayoutGap.xs="4px">
    
    
    <div fxFlex="30%" fxFlex.xs="100%" fxLayout="column" fxLayout.xs="column" fxLayoutGap="2px"  >
        <div fxFlex="100%" fxFlex.xs="100%" fxLayout="row" fxLayout.xs="column" fxLayoutGap="2px">
            <div fxFlex="75%" fxFlex.xs="100%">
                <mat-form-field class="quote-mat-form referenceInput">
                    <mat-label>Reference #</mat-label>
                    <input class="ut-reference-num" matInput readonly [value]="quoteResponseInfo?.referenceNumber">
                    <mat-hint align="end" class="hint">Order # from your internal system</mat-hint>
                </mat-form-field>
            </div>
            <div fxFlex="25%" fxFlex.xs="100%">
                <mat-form-field class="quote-mat-form moveTypeInput">
                    <mat-label>Move Type</mat-label>
                    <input  class="ut-move-type" matInput readonly [value]="moveType">
                </mat-form-field>
            </div>
        </div>
        
        <mat-form-field class="quote-mat-form">
            <mat-label>Affiliate</mat-label>
            <input  class="ut-move-type" matInput readonly [value]="affiliate">
        </mat-form-field>
        

    </div>
    <div fxFlex="calc(70%-32px)" fxFlex.xs="100%" fxLayout="column" fxLayout.xs="column" fxLayoutGap="10px" fxLayoutGap.xs="14px">
    
        
        <div fxFlex="100%" fxLayout="row" fxLayout.xs="column" fxFlex.xs=100% fxLayoutGap="10px" fxLayoutGap.xs="14px"> 
            
            <div fxFlex="61%" fxFlex.xs="100%"  fxLayout="row" fxLayout.xs="column" >
                <div  fxFlex="100%" fxLayout="column" fxLayout.xs="column">
                    <p class="sectionSubTitle" tabindex="0" aria-label="Van Line Move Details">Van Line Move Details</p>
                    <mat-card class="quoteTile quoteVaneLineTile" fxFlex="100%" fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" fxLayoutGap.xs="0px">
                     
                        <div fxFlex="33%" fxFlex.xs="100%">
                            <mat-form-field class="quoteCostMatFormField quote-mat-form">
                                <mat-label>Van Line Move Cost</mat-label>
                                <input class="ut-vanline-move-cost" matInput readonly [value]="vaneLineCost">
                            </mat-form-field>
                       </div>
                       <div fxFlex="33%" fxFlex.xs="100%">
                            <mat-form-field class="quoteCostMatFormField quote-mat-form">
                                <mat-label>Estimated Distance</mat-label>
                                <input class="ut-estimated-distance" matInput readonly [value]="estimatedDistance">
                            </mat-form-field>
                       </div>
                       <div fxFlex="33%" fxFlex.xs="100%">
                            <mat-form-field class="quoteCostMatFormField quote-mat-form">
                                <mat-label>Estimated Weight</mat-label>
                                <input class="ut-estimated-weight" matInput readonly [value]="estimatedWeight">
                            </mat-form-field>
                       </div>
                    
                    </mat-card>
                </div>
                
            </div>



            <!-- For Non- Discountable Details  -->
            <div fxFlex="calc(39%-10px)" fxFlex.xs="100%" fxLayout="row" fxLayout.xs="column" >
                <div  fxFlex="100%" fxLayout="column" fxLayout.xs="column">
                    <p class="sectionSubTitle" tabindex="0" aria-label="Non-Discountable Details">Non-Discountable Details</p>
                    <mat-card class="quoteTile" fxFlex="100%" fxLayout="row" fxLayout.xs="row" fxLayoutGap="10px" fxLayoutGap.xs="0px"> 
                        <div fxFlex="50%" fxFlex.xs="100%">
                            <mat-form-field class="quoteCostMatFormField quoteStorageFormField quote-mat-form">
                                <mat-label>Speciality Services</mat-label>
                                <input *ngIf="quoteResponseInfo?.nonDiscountableThirdPartyServices" matInput readonly [value]="nonDiscountableThirdPartyServicesVal">
                                <input *ngIf="!quoteResponseInfo?.nonDiscountableThirdPartyServices" matInput readonly value="N/A" class="naInput">
                            </mat-form-field>
                       </div>
                        <div fxFlex="50%" fxFlex.xs="100%">
                            <mat-form-field class="quoteCostMatFormField quoteStorageFormField quote-mat-form">
                                <mat-label>Other Services</mat-label>
                                <input *ngIf="quoteResponseInfo?.nonDiscountableOthers" matInput readonly [value]="nonDiscountableOthersVal">
                                <input *ngIf="!quoteResponseInfo?.nonDiscountableOthers" matInput readonly value="N/A" class="naInput">
                            </mat-form-field>
                        </div>
                    </mat-card>
                </div>
                
            </div>

        </div>




        <!--For Storage  details-->
        <div fxFlex="100%"  fxLayout="row" fxFlex.xs=100% fxLayoutGap="10px" fxLayoutGap.xs="4px">
            <div  fxFlex="100%" fxLayout="column" fxLayout.xs="column">
                <p class="sectionSubTitle" tabindex="0" aria-label="Storage Details">Storage Details</p>
                <mat-card *ngIf="(quoteResponseInfo?.bidAmountStorage) && (quoteResponseInfo?.bidAmountStorage !== '0')" class=" quoteTile" fxFlex="100%" fxLayout="row" fxLayout.xs="row">
                    <div fxFlex="30%" fxFlex.xs="100%">
                        <mat-form-field class="quoteStorageFormField quote-mat-form">
                            <mat-label>Storage Cost</mat-label>
                            <input class="ut-storage-cost" matInput readonly [value]="storageCost">
                        </mat-form-field>
                    </div>                   
                    <div fxFlex="30%" fxFlex.xs="100%">
                        <mat-form-field class=" quoteStorageFormField quote-mat-form">
                            <mat-label>Days in Storage</mat-label>
                            <input class="ut-days-in-storage" *ngIf="(quoteResponseInfo?.daysInStorage)" matInput readonly [value]="daysInStorage">
                            <input class="ut-days-in-storage-cost" *ngIf="(!quoteResponseInfo?.daysInStorage)" matInput readonly value="N/A" class="naInput">
                        </mat-form-field>
                    </div>
                </mat-card>
                <mat-card *ngIf="((!quoteResponseInfo?.bidAmountStorage)|| (quoteResponseInfo?.bidAmountStorage==='0'))" class="noneTile quoteTile" fxFlex="100%" fxLayout="row" fxLayout.xs="row" fxLayoutGap="32px" fxLayoutGap.xs="15px">
                None
                </mat-card>
            </div>
            
        </div>
        


    </div>



</div>
