
<ngx-spinner type="ball-spin-clockwise-fade" size="medium"></ngx-spinner>
<div class="cdk-visually-hidden" aria-live="polite" [innerHTML]="announceStmt" aria-atomic="true"></div>
<div class="cdk-visually-hidden" aria-live="polite" [innerHTML]="sortStatement" aria-atomic="true"></div>
<section class="content_table">
  <h1 class="totalQuoteReq" attr.aria-label="{{ totalQuoteRequest }} Quote Requests">{{ totalQuoteRequest }} Quote
    Requests</h1>
  <br />
  <div>

    <!---Search box begins-->
    <section class="search_table">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <span role="search" fxFlex="60%" fxLayoutAlign="start">
          <mat-form-field appearance="outline" class="search-within-table search-box">
            <mat-icon matPrefix>search</mat-icon>
            <input aria-atomic="true" matInput id="searchInput" #searchInput autocomplete="off"
              placeholder="Search within table for..." width="80%" fxFlex.sm="65" [(ngModel)]="filterText"
              (input)="applyFilter($event.target.value)">
            <button matTooltip="clear" mat-button *ngIf="filterText" matSuffix mat-icon-button 
              (click)="resetList()" aria-label="clear">
              <mat-icon id="search_icon">close</mat-icon>
            </button>
          </mat-form-field>

          <span role="selectcolumn" fxLayoutAlign="start center">
            <button id="selectColumns" (click)="openTableOptions()" aria-label="Select-Columns">
              <mat-icon matTooltip="Select columns" matTooltipPosition="right" class="viewsetting-icon">view_column
              </mat-icon>
            </button>
          </span>
        </span>
      </div>
    </section>
    <!--Search box ends-->
  </div>
  <br />
  <div>
    <section class="mat-elevation-z8">
      <div *ngIf="ELEMENT_DATA.length>0 && dataSource.filteredData.length>0" style="overflow:auto;">
        <table mat-table  [dataSource]="dataSource" aria-describedby="table-candidates" matSort [matSortActive]="sortBy"
          matSortDirection="desc" (matSortChange)="sortData($event)">
          <!-- Full Name Column -->
          <ng-container matColumnDef="fullName" sticky>
            <th id="" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Full Name">Full
              Name</th>
            <td mat-cell *matCellDef="let element" class="cLinks" [ngClass]="{highlighted: selection.isSelected(row)}"
              (click)="navigateToBudgetSummary(element, $event);return" for="statusInfo" tabindex="0">

              <a href="#" (click)="navigateToBudgetSummary(element, $event)" role="Fullname" tabindex="0" class="text-as-link" id="statusInfo" [attr.aria-label]="element.fullName" aria-describedby="quoteData"
                [innerHTML]="element.fullName | highlightSearch: filterText"></a>
            </td>
          </ng-container>
          <!-- order Column -->
          <ng-container matColumnDef="orderReference">
            <th id='' mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Order #">Order #
            </th>
            <td mat-cell *matCellDef="let element">
              <span
                [innerHTML]="((element.orderReference !== undefined || null) ? (element.orderReference ) : '-') | highlightSearch: filterText"
                aria-describedby="((element.quoteStatus !== undefined || element.quoteStatus !== null) ?
              element.quoteStatus : '-') | highlightSearch: filterText"></span><br />
            </td>
          </ng-container>

          <!-- Departure Column -->
          <ng-container matColumnDef="departure">
            <th id="" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Departure">
              Departure</th>
            <td mat-cell *matCellDef="let element"
              [innerHTML]="((element.departure !== undefined || null) ? (element.departure ) : '') | highlightSearch: filterText">
            </td>
          </ng-container>

          <!-- Destination Column -->
          <ng-container matColumnDef="destination">
            <th id="" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Destination">
              Destination</th>
            <td mat-cell *matCellDef="let element"
              [innerHTML]="(((element.destination !== undefined)) ? (element.destination ) : '') | highlightSearch: filterText">
            </td>
          </ng-container>

          <!-- Company -->
          <ng-container matColumnDef="company">
            <th id="" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Company">Company
            </th>
            <td mat-cell *matCellDef="let element"
              [innerHTML]="(((element.company !== undefined)) ? (element.company ) : '-') | highlightSearch: filterText">
            </td>
          </ng-container>


          <!-- Status Column -->
          <ng-container matColumnDef="quoteStatusDate" stickyEnd>
            <th id="" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Status">Status
            </th>
            <td mat-cell *matCellDef="let element" id="quoteData">
              <span [innerHTML]="((element.quoteStatus !== undefined || element.quoteStatus !== null) ?
               element.quoteStatus : '-') | highlightSearch: filterText" [id]="((element.quoteStatus !== undefined || element.quoteStatus !== null) ?
               element.quoteStatus : '-') | highlightSearch: filterText"></span><br />
              <span class="listStatusDate"
                [innerHTML]="((element.quoteRequestId !== undefined || element.quoteRequestId !== null) ? ('on&nbsp;' + (element?.quoteStatusDate | date: 'yyyy-MM-dd')) : '') | highlightSearch: filterText"></span>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            (click)="row.status === 'Ready for Review'||row.status === 'Payment Pending' ?navigateToBudgetSummary(row.candidateId, $event):return;"
            [ngClass]="{highlighted: selection.isSelected(row)}"></tr>
        </table>
      </div>
      <div role="Paginator" [hidden]="!(ELEMENT_DATA.length>0 && dataSource.filteredData.length>0)">
        <mat-paginator tabindex="-1" aria-label="Paginator" [length]="pageInfo.totalCount"
          [pageSize]="pageInfo.pageSize" [pageSizeOptions]="[5, 10, 20]" (page)="onPagination($event)"></mat-paginator>
      </div>
    </section>
  </div>
  <div role="NoRecordsFound" tabindex="0" [hidden]="ELEMENT_DATA.length>0 && dataSource.filteredData.length>0"
    class="invalidData">
    No records found
  </div>
</section>
<div *ngIf="selection.selected.length > 0" class="candidate-highlight-whitebg">
  <button mat-button class="mat-button contained-button" (click)="withdrawCandidates()" id="withdrawBtn">Withdraw
    <span *ngIf="selection.selected.length===1" class=" cost-model-btn-txt">Candidate</span>
    <span *ngIf="selection.selected.length>1" class=" cost-model-btn-txt">{{selection.selected.length}}
      Candidates</span>
  </button>
</div>