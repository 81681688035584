import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-contract-territory-popup',
  templateUrl: './edit-contract-territory-popup.component.html',
  styleUrls: ['./edit-contract-territory-popup.component.scss']
})
export class EditContractTerritoryPopupComponent implements OnInit {

  territoryList: any[] = [
    {
      displayValue: 'All states',
      value: '/US'
    }
  ];

  territoryTariffList: any[] = [{
    displayValue: 'Cartus Rate Schedule',
    value: 'Cartus Rate Schedule'
  }];

  discountTypesList: any[] = [
    {
      displayValue: 'Standard Move',
      value: 'Standard Move'
    }
  ];

  commissionTypesList: any[] = [
    {
      displayValue: 'Micro Move',
      value: 'Micro Move'
    }
  ];
  deliveryConfigurationFormGroup: UntypedFormGroup;
  isEditView: boolean;

  constructor(public dialogRef: MatDialogRef<EditContractTerritoryPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: UntypedFormGroup) {

  }

  ngOnInit() {
    if (this.dialogData) {
      console.log(this.dialogData);
      this.deliveryConfigurationFormGroup = this.getTerritoryDeliveryConfig(this.dialogData.getRawValue());
      this.deliveryConfigurationFormGroup.enable();
      if (this.deliveryConfigurationFormGroup.get('region') && this.deliveryConfigurationFormGroup.get('region').value) {
        this.isEditView = true;
      }
    }
  }

  onCloseClick () {
    this.dialogRef.close();
  }

  saveChanges () {
    this.dialogRef.close(this.deliveryConfigurationFormGroup.getRawValue());
  }


  removeDiscount(configIndex, discountsIndex) {
    (this.deliveryConfigurationFormGroup.get('discounts') as UntypedFormArray).removeAt(discountsIndex);
  }

  removeCommissions(configIndex, commissionsIndex) {
    (this.deliveryConfigurationFormGroup.get('commissions') as UntypedFormArray).removeAt(commissionsIndex);
  }

  addNewDiscount (configIndex) {
    (this.deliveryConfigurationFormGroup.get('discounts') as UntypedFormArray).push(this.getNewCommissionForm());
  }

  addNewCommission (configIndex) {
    (this.deliveryConfigurationFormGroup.get('commissions') as UntypedFormArray).push(this.getNewCommissionForm());
  }

  getNewCommissionForm (val?) {
    const formGroup =  new UntypedFormGroup ({
      category: new UntypedFormControl(''),
      percentage: new UntypedFormControl(''),
      effectiveFrom: new UntypedFormControl(''),
      effectiveTo: new UntypedFormControl(''),
    });

    if (val) {
      formGroup.patchValue(val);
    }

    return formGroup;
  }


  getTerritoryDeliveryConfig (element?, formGroup?) {
    const configuration = formGroup ? formGroup : new UntypedFormGroup({
      'region': new UntypedFormControl(''),
      'regulation': new UntypedFormControl(''),
      'tariff': new UntypedFormControl(''),
      'commissions': new UntypedFormArray([]),
      'discounts': new UntypedFormArray([])
    });

    if (element) {
      if (formGroup) {
        (configuration.get('commissions') as UntypedFormArray).clear();
        (configuration.get('discounts') as UntypedFormArray).clear();
      }
      configuration.patchValue(element);
      if (element.commissions && element.commissions.length) {
        element.commissions.forEach((commission, Index) => {
          (configuration.get('commissions') as UntypedFormArray).push(this.getForm(commission));
        });
      } else {
        (configuration.get('commissions') as UntypedFormArray).push(this.getForm());
      }
      if (element.discounts && element.discounts.length) {
        element.discounts.forEach((discount, Index) => {
          (configuration.get('discounts') as UntypedFormArray).push(this.getForm(discount));
        });
      } else {
        (configuration.get('discounts') as UntypedFormArray).push(this.getForm());
      }
    } else {
      (configuration.get('discounts') as UntypedFormArray).push(this.getForm());
      (configuration.get('commissions') as UntypedFormArray).push(this.getForm());
    }

    return configuration;
  }

  getForm (element?) {
    const formGroup =  new UntypedFormGroup ({
      category: new UntypedFormControl(''),
      percentage: new UntypedFormControl(''),
      effectiveFrom: new UntypedFormControl(''),
      effectiveTo: new UntypedFormControl(''),
    });

    if (element) {
      formGroup.patchValue(element);
    }

    return formGroup;
  }


  isFormInvalid () {
    return !this.deliveryConfigurationFormGroup.get('region').value || !this.deliveryConfigurationFormGroup.get('tariff').value;
  }

}
