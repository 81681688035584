<form [formGroup]="partyDetailsForm">
    <div fxLayout="row wrap" class="header-row" *ngIf="isModelView">
        <h4 fxFlex="50" class="header-label">{{dialogData && dialogData.action === 'add' ? "Add Supplier" :  "Edit Supplier"}}</h4>
        <div fxFlex="50" fxLayoutAlign="end center">
            <mat-icon class="close-icon" (click)="onCloseClick()">close</mat-icon>
        </div>
    </div>
    <div fxLayout="row wrap"  fxLayoutGap="32.5px">
        <div fxFlex="40%" fxFlex.md="40%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
            <mat-form-field>
                <mat-label>Supplier Legal Name</mat-label>
                <input class="businessUnit" matInput  formControlName="entityName">
            </mat-form-field>
        </div> 
        <div fxFlex="40%" fxFlex.md="40%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
            <mat-form-field>
                <mat-label>Supplier Display Name</mat-label>
                <input class="level" matInput formControlName="preferredName">
            </mat-form-field>
        </div>
        <div fxFlex.lg="40%" fxFlex.md="40%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
            <mat-form-field>
                <mat-label>Supplier Vendor #</mat-label>
                <input class="level" matInput formControlName="vendorNumber">
            </mat-form-field>
        </div>
    </div>
    <div fxLayout="row wrap" formGroupName="primaryLoactionDetails" fxLayoutGap="32.5px" class="supplier-personal-info">
        <h3 fxFlex="100%">Primary Location</h3>
        <div fxFlex.lg="40%" fxFlex.md="40%" fxFlex.xs="100%" class="item item-1 candidate-review-field supplier-personal-info-field">
            <mat-form-field>
                <mat-label>Country</mat-label>
                <mat-select formControlName="country">
                    <mat-option *ngFor="let country of countyList;" [value]="country.value">{{country.displayValue}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex="auto" fxFlex.md="40%" fxFlex.xs="100%" class="item item-1 candidate-review-field supplier-personal-info-field">
            <mat-form-field>
                <mat-label>Street Address</mat-label>
                <input class="level" matInput formControlName="streetAddress">
            </mat-form-field>
        </div>
        <div fxFlex="40%" fxFlex.md="40%" fxFlex.xs="100%" class="item item-1 candidate-review-field supplier-personal-info-field">
            <mat-form-field>
                <mat-label>City</mat-label>
                <input class="level" matInput formControlName="city">
            </mat-form-field>
        </div>
        <div fxFlex="20%" fxFlex.md="40%" fxFlex.xs="100%" class="item item-1 candidate-review-field supplier-personal-info-field">
            <mat-form-field>
                <mat-label>State</mat-label>
                <mat-select formControlName="state">
                    <mat-option *ngFor="let state of stateList;" [value]="state.value">{{state.displayValue}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex="20%" fxFlex.md="40%" fxFlex.xs="100%" class="item item-1 candidate-review-field supplier-personal-info-field">
            <mat-form-field>
                <mat-label>Zipcode</mat-label>
                <input class="level" matInput formControlName="zipcode">
            </mat-form-field>
        </div>
    </div>
    <mat-dialog-actions align="end" *ngIf="isModelView">
        <button mat-button color="primary" (click)="onCloseClick()">CANCEL</button>
        <button mat-raised-button color="primary" class="save_btn" [ngClass]="{'save_btn_disabled':!partyDetailsForm.valid}" (click)="saveChanges()" [disabled]="!partyDetailsForm.valid">{{dialogData && dialogData.action === 'add' ? "Save" :  "Save Changes"}}</button>
    </mat-dialog-actions>
</form>
