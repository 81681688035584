<ngx-spinner type="ball-spin-clockwise-fade" size="medium"></ngx-spinner>
<div class="cdk-visually-hidden" aria-live="polite" aria-atomic="true" [innerHTML]="announceStmt"></div>
<div class="cdk-visually-hidden" aria-live="polite" [innerHTML]="sortStatement" aria-atomic="true"></div>
<section class="content_table">
  <h1 class="totalWorkorder" attr.aria-label="{{ totalWorkOrder }} Work Orders"> {{ totalWorkOrder }} Work Orders </h1>
  <br/>
  <div>   
    <!---Search box begins-->   
    <section class="search_table">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <span role="search" fxFlex="60%" fxLayoutAlign="start">
            <mat-form-field appearance="outline" class="search-within-table search-box">
              <mat-icon matPrefix>search</mat-icon>
              <input aria-atomic="true" matInput id="searchInput" #searchInput autocomplete="off" placeholder="Search within table for..." width="80%" fxFlex.sm="65"
               [(ngModel)]="filterText" (input)="applyFilter($event.target.value)">
              <button matTooltip="clear button" mat-button *ngIf="filterText" matSuffix mat-icon-button aria-label="Clear" (click)="resetList()">
                <mat-icon id="search_icon">close</mat-icon>
              </button>
            </mat-form-field>
            
            <span fxLayoutAlign="start center">
              <button id="selectColumns" (click)="openTableOptions()" aria-label="Select-Columns">
                <mat-icon matTooltip="Select columns" matTooltipPosition="right" class="viewsetting-icon">view_column</mat-icon>
              </button>
            </span>
        </span>        
      </div>
    </section> 
    <!--Search box ends-->
  </div>
  <br/>
  <div>
    <section class="mat-elevation-z8">
      <div *ngIf="workOrders.length>0 && dataSource.filteredData.length>0" style="overflow:auto;">
        <table mat-table [dataSource]="dataSource" aria-describedby="table-candidates" matSort [matSortActive]="sortBy"
          matSortDirection="desc" (matSortChange)="sortData($event)">
          <!-- Full Name Column -->
          <ng-container matColumnDef="partyName" sticky>
            <th id="" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Full Name"> Full Name </th>
            <td mat-cell *matCellDef="let element" class="cLinks" 
            (click)="navigateToWorkOderDetails(element, $event);return" for="statusInfo" tabindex="0">
              <a href="#" (click)="navigateToWorkOderDetails(element, $event);" role="Fullname" tabindex="0" class="text-as-link"
              id="statusInfo" [attr.aria-label]="element.fullName" aria-describedby="quoteData"
               [innerHTML]="(element.partyName) | highlightSearch: filterText"></a>
               </td>
          </ng-container>         
          <!-- order Column -->
          <ng-container matColumnDef="orderReference">
            <th id='' mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Order#"> Order # </th>
            <td mat-cell *matCellDef="let element">
              <span [innerHTML]="(element.orderReference? element.orderReference : '-')| highlightSearch: filterText"></span><br />              
            </td>
          </ng-container>

          <!-- Departure column -->
          <ng-container matColumnDef="departureState">
            <th id="" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Departure"> Departure</th>
            <td mat-cell *matCellDef="let element">
              <span [innerHTML]="(element.departureState +', ' +element.departureCity) | highlightSearch: filterText"></span>
            </td>
          </ng-container>
          <!-- Departure column -->
          <!-- Destination Column -->
          <ng-container matColumnDef="destinationState">
            <th id="" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Destination"> Destination </th>
              <td mat-cell *matCellDef="let element" 
              [innerHTML]="(element.destinationState +', ' +element.destinationCity) | highlightSearch: filterText">
            </td>
          </ng-container>

           <!-- Company -->
           <ng-container matColumnDef="company">
            <th id="" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Company">Company</th>
            <td mat-cell *matCellDef="let element" [innerHTML]="(element.company? element.company : '-') | highlightSearch: filterText"></td>
          </ng-container> 
           <!-- AgentName -->
           <ng-container matColumnDef="bookedWithContactName">
            <th id="" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Agent Name">Agent Name</th>
            <td mat-cell *matCellDef="let element" [innerHTML]="(element.bookedWithContactName ? element.bookedWithContactName  : '-') | highlightSearch: filterText"></td>
          </ng-container> 
                    
          <!-- Status Column -->   
          <ng-container matColumnDef="workOrderStatusDate" stickyEnd>
            <th id="" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Status"> Status </th>
            <td mat-cell *matCellDef="let element" id="quoteData">
              <span [innerHTML]="(element.workOrderStatus ? element.workOrderStatus : '-') | highlightSearch: filterText"></span><br/>
              <span class="listStatusDate" [innerHTML]="element.workOrderStatusDate? ('on&nbsp;' + (element.workOrderStatusDate | date: 'yyyy-MM-dd')) : ''"></span>
            </td>
          </ng-container>
          <!-- Status Column -->          
          <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
      <div role="Paginator" [hidden]="!(workOrders.length>0 && dataSource.filteredData.length>0)">
        <mat-paginator tabindex="-1" aria-label="Paginator" [length]="pageInfo.totalCount" [pageSize]="pageInfo.pageSize" [pageSizeOptions]="[5, 10, 20]"
          (page)="onPagination($event)"></mat-paginator>
      </div>
    </section>
  </div>
  <div role="NoRecordsFound" tabindex="0" [hidden]="workOrders.length>0 && dataSource.filteredData.length>0" class="invalidData">
    No records found
  </div>
</section>

