<div class="settingsContainer" flexLayout fxLayout.xs="column">
   <div mat-dialog-title disableTypography className={styles.dialogTitle} fxLayout="row" fxLayoutAlign="space-between"
     fxLayoutGap="20px">
     <h1 class="candidate-dialogtitle" mat-dialog-title title="Table Columns" role="heading" aria-level="1">Table Columns</h1>
     <a href="#" class="close-icon" (click)="onNoClick($event)" title="close" tabindex="0">
       <mat-icon>close</mat-icon>
     </a>
   </div>
   <mat-dialog-content>
     <div class="settings-tablecol" tabindex='0'>
       <div class="settings-scrolldiv" tabindex="0">
         <mat-grid-list [cols]="gridColumn" rowHeight="35px" tabindex="0">
           <mat-grid-tile role="list" *ngFor="let selection of columnsList; let i = index;" tabindex='0'>
             <mat-checkbox role="listitem" [checked]="selection.flag"  tabindex='0' [disabled]="selection.disabled" 
             [attr.aria-label]="selection.displayName" (change)="updateChkbxArray(selection)">{{selection.displayName}}</mat-checkbox>
 
           </mat-grid-tile>
         </mat-grid-list>
       </div>
     </div>
     <div class="settings-selectedcount"><span>{{selectedColumnsList.length}}</span> Selected</div>
   </mat-dialog-content>
   <mat-dialog-actions >
     <div class="statusBtn" >
       <button (click)="resetValues()" mat-button color="primary" id="reset" class="text-button" tabindex="0">RESET</button>
       <button class="contained-button mat-button" id="save" (click)="save()" tabindex="0">OK</button>
     </div>
   </mat-dialog-actions>
 </div>