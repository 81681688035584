import { Component, Input, OnInit } from '@angular/core';
import { ServiceDetail } from '../../../models/supplier.model';

@Component({
  selector: 'app-supplier-list-contract-status',
  templateUrl: './supplier-list-contract-status.component.html',
  styleUrls: ['./supplier-list-contract-status.component.scss'],
})
export class SupplierListContractStatusComponent implements OnInit {
  @Input() serviceDetails: ServiceDetail[];

  reviewCount = 0;
  activeCount = 0;
  inactiveCount = 0;

  constructor() {}

  ngOnInit() {
    if (this.serviceDetails && this.serviceDetails.length > 0) {
      this.calculateContractStatus(this.serviceDetails);
    }
  }

  calculateContractStatus(serviceDetails: ServiceDetail[]) {
    serviceDetails.forEach((serviceDetail) => {
      if (serviceDetail.contractData && serviceDetail.contractData.status) {
        switch (serviceDetail.contractData.status) {
          case 'Approval Requested':
            this.reviewCount++;
            break;
          case 'Active':
            this.activeCount++;
            break;
          case 'Inactive':
            this.inactiveCount++;
            break;
          default:
            break;
        }
      }
    });
  }

}
