<div class="authorize-dialog" role="dialog" aria-modal="true" aria-labelledby="quoteResponseTitle" >
    <div mat-dialog-title disableTypography fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="20px" fxLayoutGap.xs="0em">
       <div class="candidate-dialogtitle">
          <h3 class="quoteResponseTitle" >Quote Response</h3>
       </div>
       <a href="#" tabindex="0" matTooltip="Close" aria-label="close" role="link" class="close-icon" id="close_icon" (click)="close($event)">
          <mat-icon>close</mat-icon>
       </a>
    </div>
    <div mat-dialog-content class="quoteform">
       <form fxLayout="column" class="middle-section" [formGroup]="editCandidateForm" (ngSubmit)="submitQuoteResponse()" novalidate>
            <div class="form-group middle-section" >
                <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" fxLayout.xs="column"
                    fxLayoutGap.xs="0em">
                    <div fxFlex="30%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                        <mat-form-field>
                            <input matInput aria-required="false" type="text" class="form-control focusInput" autocomplete="off" formControlName="reference" placeholder="Reference #"/> 
                            <mat-hint align="end" aria-label="Order # from your internal system" class="hint">Order # from your internal system</mat-hint>
                            <mat-error [attr.role]="'presentation'" [attr.aria-live]="'assertive'" [attr.aria-atomic]="'true'">You must enter reference #</mat-error>
                        </mat-form-field>
                    </div>
                    <div fxFlex="30%" fxLayout.xs="column" fxFlex.xs="100%">
                        <mat-form-field>
                            <mat-label>Move Type</mat-label>
                            <mat-select role="listbox" class="form-control focusInput" [(value)]="selected" formControlName="moveType" ngDefaultControl aria-label="moveType">
                                <mat-option [value]="moveType" *ngFor="let moveType of MoveType">{{ moveType }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div fxFlex="30%" fxLayout.xs="column" fxFlex.xs="100%">
                        <mat-form-field>
                        <mat-label>Affiliate</mat-label>
                            <mat-select class="form-control focusInput" [(value)]="select" formControlName="affiliate" ngDefaultControl aria-label="affiliate">
                                <mat-option [value]="affiliate" *ngFor="let affiliate of Affiliate">{{ affiliate }}</mat-option>
                            </mat-select>
                            <mat-error [attr.role]="'presentation'" [attr.aria-live]="'assertive'" [attr.aria-atomic]="'true'">You must select Affiliate</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <br/>
            <p>Van Line Move Details</p>
            <mat-card class="quotematcard paddingBottom">
                <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
                    fxLayoutGap.xs="0em">
                    <div fxFlex="47%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                        <mat-form-field class="quotematformfield">
                            <input matInput type="text" aria-required="false" autocomplete="off" class="focusInput" [appAmountFormat]="editCandidateForm.controls['bidAmount']" onkeypress="return event.charCode >= 48 && event.charCode<=57" min="1" 
                            formControlName="bidAmount" placeholder="Van Line Move Cost"/>
                            <span class="units">USD</span>
                            <mat-error [attr.role]="'presentation'" [attr.aria-live]="'assertive'" [attr.aria-atomic]="'true'" *ngIf="editCandidateForm.get('bidAmount').hasError('required')" >Van line move cost must be entered</mat-error>
                            <mat-error [attr.role]="'presentation'" [attr.aria-live]="'assertive'" [attr.aria-atomic]="'true'" *ngIf="editCandidateForm.get('bidAmount').hasError('valueInvalid')">Value amount must be numeric</mat-error>
                            <mat-error [attr.role]="'presentation'" [attr.aria-live]="'assertive'" [attr.aria-atomic]="'true'" *ngIf="editCandidateForm.get('bidAmount').hasError('min')">Van line move cost must be entered</mat-error>
                        </mat-form-field>
                    </div>
                    <div fxFlex="47%" fxLayout="column-reverse" fxLayoutALign="space-between" fxLayoutGap="2em" fxFlex.xs="100%">
                        <mat-form-field class="quotematformfield" aria-label="Estimated Mileage"> 
                            <input matInput aria-required="false" autocomplete="off" type="text" class="focusInput" [appAmountFormat]="editCandidateForm.controls['estimatedDistance']" onkeypress="return event.charCode >=  48 && event.charCode<=57" min="1"
                            formControlName="estimatedDistance"  placeholder="Estimated Mileage" />
                            <span class="units">Miles</span>
                            <mat-error [attr.role]="'presentation'" [attr.aria-live]="'assertive'" [attr.aria-atomic]="'true'" *ngIf="editCandidateForm.get('estimatedDistance').hasError('required')">Estimated mileage must be entered</mat-error>
                            <mat-error [attr.role]="'presentation'" [attr.aria-live]="'assertive'" [attr.aria-atomic]="'true'" *ngIf="editCandidateForm.get('estimatedDistance').hasError('valueInvalid')">Estimated mileage must be numeric</mat-error>
                            <mat-error [attr.role]="'presentation'" [attr.aria-live]="'assertive'" [attr.aria-atomic]="'true'" *ngIf="editCandidateForm.get('estimatedDistance').hasError('min')">Estimated mileage must be entered</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <br/>
                <div class="input-row estwhgt" fxLayout="row" fxLayoutAlign="column-end" fxLayoutGap="2em" fxLayout.xs="column"
                    fxLayoutGap.xs="0em">
                    <div fxFlex="47%" fxLayoutAlign="column-end" fxLayout.xs="column" fxFlex.xs="100%">
                        <mat-form-field class="quotematformfield" aria-label="Estimated Weight">
                            <input matInput type="text" aria-required="false" autocomplete="off" class="focusInput" [appAmountFormat]="editCandidateForm.controls['estimatedWeight']" onkeypress="return event.charCode >= 48 && event.charCode<=57" min="1"
                            formControlName="estimatedWeight"  placeholder="Estimated Weight" />
                            <span class="units">Lb</span>
                            <mat-error [attr.role]="'presentation'" [attr.aria-live]="'assertive'" [attr.aria-atomic]="'true'" *ngIf="editCandidateForm.get('estimatedWeight').hasError('required')">Estimated weight must be entered</mat-error>
                            <mat-error [attr.role]="'presentation'" [attr.aria-live]="'assertive'" [attr.aria-atomic]="'true'" *ngIf="editCandidateForm.get('estimatedWeight').hasError('valueInvalid')">Estimated weight must be numeric</mat-error>
                            <mat-error [attr.role]="'presentation'" [attr.aria-live]="'assertive'" [attr.aria-atomic]="'true'" *ngIf="editCandidateForm.get('estimatedWeight').hasError('min')">Estimated weight must be entered</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </mat-card>
            <p *ngIf="nonDiscountPanel.expanded" class="storageTitle">Non-Discountable Details</p>
            <mat-accordion  class="quotematcard">
                <mat-expansion-panel #nonDiscountPanel (opened)="openNonDiscountPanel()" [expanded]="isNonDiscountPanelOpen" (closed)="closeNonDiscountPanel()" hideToggle  class="expansionPanelSection">
                    <mat-expansion-panel-header [collapsedHeight]="matCollapseHeaderHeight" [expandedHeight]="matExpansionHeaderHeight">
                        <mat-panel-title *ngIf="!nonDiscountPanel.expanded" class="nondiscountableheaderSection">
                        <span id="Add_NonDiscountable_Details" arial-label="Add Optional Non-Discountable Details" class="panel-heading">Add Optional Non-Discountable Details</span>
                        </mat-panel-title>         
                        <mat-icon *ngIf="!nonDiscountPanel.expanded" aria-hidden="true" class="maticon nondiscountableplusicon">add_circle_outline</mat-icon>                
                    </mat-expansion-panel-header>
                    <div class="middle-section">
                        <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="row"
                        fxLayoutGap.xs="0em">
                            <div fxFlex="95%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="92%"></div>
                            <div fxFlex="5%" fxLayoutAlign="column-end" fxLayout.xs="column"  fxFlex.xs="8%">
                                <a href="#" tabindex="0" matTooltip="Close" aria-label="close Non-Discountable Details Dropdown" role="link" (click)="$event.preventDefault();toggleAccordionPanel('isNonDiscountPanelOpen')">
                                    <mat-icon class="maticon storagecloseicon">highlight_off</mat-icon>
                                </a>
                            </div>
                        </div>
                        <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
                        fxLayoutGap.xs="0em">
                        <div fxFlex="47%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                            <mat-form-field class="quotematformfield" aria-label="Specialty Services">
                                <input matInput type="text" aria-required="false" autocomplete="off" [appAmountFormat]="editCandidateForm.controls['nonDiscountableThirdPartyServices']" 
                                    onkeypress="return event.charCode >= 48 && event.charCode<=57" 
                                    min="1"
                                formControlName="nonDiscountableThirdPartyServices" class="focusInput" placeholder="Specialty Services" /><span class="units">USD</span>
                                <mat-error [attr.role]="'presentation'" [attr.aria-live]="'assertive'" [attr.aria-atomic]="'true'" *ngIf="editCandidateForm.get('nonDiscountableThirdPartyServices').hasError('required')">Specialty Services Cost must be entered</mat-error>
                                <mat-error [attr.role]="'presentation'" [attr.aria-live]="'assertive'" [attr.aria-atomic]="'true'" *ngIf="editCandidateForm.get('nonDiscountableThirdPartyServices').hasError('valueInvalid')">Specialty Services Cost must be numeric</mat-error>
                                <mat-error [attr.role]="'presentation'" [attr.aria-live]="'assertive'" [attr.aria-atomic]="'true'" *ngIf="editCandidateForm.get('nonDiscountableThirdPartyServices').hasError('min')">Specialty Services Cost must be entered</mat-error>                            
                            </mat-form-field>
                        </div>
                        <div fxFlex="47%" fxLayout.xs="column" fxFlex.xs="100%">
                            <mat-form-field class="quotematformfield"  aria-label="Other Services">
                                <input matInput type="text" aria-required="false" autocomplete="off" [appAmountFormat]="editCandidateForm.controls['nonDiscountableOthers']" 
                                    onkeypress="return event.charCode >= 48 && event.charCode<=57" 
                                    min="1" 
                                formControlName="nonDiscountableOthers" class="focusInput" placeholder="Other Services"/><span class="units">USD</span>
                                <mat-error [attr.role]="'presentation'" [attr.aria-live]="'assertive'" [attr.aria-atomic]="'true'" *ngIf="editCandidateForm.get('nonDiscountableOthers').hasError('required')">Other Services Cost must be entered</mat-error>
                                <mat-error [attr.role]="'presentation'" [attr.aria-live]="'assertive'" [attr.aria-atomic]="'true'" *ngIf="editCandidateForm.get('nonDiscountableOthers').hasError('valueInvalid')">Other Services Cost must be numeric</mat-error>
                                <mat-error [attr.role]="'presentation'" [attr.aria-live]="'assertive'" [attr.aria-atomic]="'true'" *ngIf="editCandidateForm.get('nonDiscountableOthers').hasError('min')">Other Services Cost must be entered</mat-error>                            
                            </mat-form-field>
                        </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
            <p *ngIf="storage.expanded" class="storageTitle">Storage Details</p>
            <mat-accordion  class="quotematcard">
                <mat-expansion-panel #storage (opened)="openStorage()" [expanded]="isStorageOpen" (closed)="closeStorage()" hideToggle class="expansionPanelSection">
                    <mat-expansion-panel-header [collapsedHeight]="matCollapseHeaderHeight"
                        [expandedHeight]="matExpansionHeaderHeight">
                        <mat-panel-title *ngIf="!storage.expanded">
                            <span id="Add_Storage_Details" arial-label="Add Storage Details" class="panel-heading">Add Storage Details</span>
                        </mat-panel-title>         
                        <mat-icon *ngIf="!storage.expanded" class="maticon">add_circle_outline</mat-icon>                
                    </mat-expansion-panel-header>
                    <div class="middle-section">
                        <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="row" fxLayoutGap.xs="0em">
                            <div fxFlex="95%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="92%"></div>
                            <div fxFlex="5%" fxLayoutAlign="column-end" fxLayout.xs="column"  fxFlex.xs="8%">
                                <a href="#" tabindex="0" matTooltip="Close" aria-label="close Add Storage Details Dropdown" role="link" (click)="$event.preventDefault();toggleAccordionPanel('isStorageOpen')">
                                    <mat-icon class="maticon storagecloseicon">highlight_off</mat-icon>
                                </a>
                            </div>
                        </div>
                        <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column" fxLayoutGap.xs="0em">
                            <div fxFlex="47%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                                <mat-form-field class="quotematformfield" aria-label="Storage Cost">
                                    <input tabindex="0" matInput type="text" aria-required="false" autocomplete="off" [appAmountFormat]="editCandidateForm.controls['bidAmountStorage']" 
                                    onkeypress="return event.charCode >= 48 && event.charCode<=57" min="1"
                                    formControlName="bidAmountStorage" placeholder="Storage Cost" />
                                    <span class="units">USD</span>
                                    <mat-error [attr.role]="'presentation'" [attr.aria-live]="'assertive'" [attr.aria-atomic]="'true'" *ngIf="editCandidateForm.get('bidAmountStorage').hasError('required')">Storage Cost must be entered</mat-error>
                                    <mat-error [attr.role]="'presentation'" [attr.aria-live]="'assertive'" [attr.aria-atomic]="'true'" *ngIf="editCandidateForm.get('bidAmountStorage').hasError('valueInvalid')">Storage Cost must be numeric</mat-error>
                                    <mat-error [attr.role]="'presentation'" [attr.aria-live]="'assertive'" [attr.aria-atomic]="'true'" *ngIf="editCandidateForm.get('bidAmountStorage').hasError('min')">Storage Cost must be entered</mat-error>                            
                                </mat-form-field>
                            </div>
                            <div fxFlex="47%" fxLayout.xs="column" fxFlex.xs="100%">
                                <mat-form-field class="quotematformfield" aria-label="bidStorageDays">
                                    <input tabindex="0" matInput type="text" aria-required="false" autocomplete="off" placeholder="Days in Storage" 
                                    formControlName="bidStorageDays" [appAmountFormat]="editCandidateForm.controls['bidStorageDays']" onkeypress="return event.charCode >= 48 && event.charCode<=57"/>
                                    <mat-error [attr.role]="'presentation'" [attr.aria-live]="'assertive'" [attr.aria-atomic]="'true'" *ngIf="editCandidateForm.get('bidStorageDays').hasError('required')">Days in Storage must be entered</mat-error>
                                    <mat-error [attr.role]="'presentation'" [attr.aria-live]="'assertive'" [attr.aria-atomic]="'true'" *ngIf="editCandidateForm.get('bidStorageDays').hasError('valueInvalid')">Days in Storage must be numeric</mat-error>
                                    <mat-error [attr.role]="'presentation'" [attr.aria-live]="'assertive'" [attr.aria-atomic]="'true'" *ngIf="editCandidateForm.get('bidStorageDays').hasError('min')">Days in Storage must be entered</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
            <br/>
            <p class="note strong center" id="info" role="alert" aria-live="assertive" aria-atomic="true">Please be aware that once you submit the quote response it cannot be updated</p>
            <div mat-dialog-actions>
                <div class="statusBtn">
                    <button class="uppercase" mat-button (click)="close($event)">Cancel</button>
                    <button type="submit" mat-button [disabled]="editCandidateForm.invalid || isNonDiscountPanelinValid" class="mat-button contained-button" aria-describedby="info">Submit Quote</button>
                </div>
            </div>
       </form>
    </div>
</div>