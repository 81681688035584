import { Component, OnInit } from '@angular/core';
import { CandidateContactInfo } from '../../models/candidate-contact-info.model';
import { AppConfigService } from '../../services/app-config.service';
import { LoggedInUserService } from '../../services/loggedin-user-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { PartySharedService } from '../../services/party-shared.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss']
})
export class AccountSettingsComponent implements OnInit {

  /**candidateContactInfo of type CandidateContactInfo */
  candidateContactInfo: CandidateContactInfo;
  candidateCompany: string;
  isNotCartusEmp = true;

  constructor(private readonly appConfig: AppConfigService,
    private readonly loggedInUserService: LoggedInUserService,
    private readonly spinner: NgxSpinnerService,
    private readonly router: Router,
    private readonly partySharedService: PartySharedService
  ) { }

  ngOnInit() {
    this.getLoggedInUserInformation();
  }

  async getLoggedInUserInformation() {
    this.spinner.show();
    await this.loggedInUserService.getLoggedInUserInformation().subscribe({
      next: async response => {
        this.spinner.hide();
        if (response) {
          response.phoneNumber = response.phoneNumber.replace(/[()-]/g, '').replace(' ' , '');
          this.candidateContactInfo = {
            candidateName: response.userName ? response.userName.replace(' ', ', ') : '-',
            emailId: response.emailAddress,
            phoneNumber: response.phoneNumber.substring(response.phoneNumber.length - 10),
            countryDialingCode: response.phoneNumber.substring(0, response.phoneNumber.length - 10).replace('+' , '')
          };
          this.candidateCompany = response.clientName;
          await this.partySharedService.getPartyId()
          .then((partyId) => this.partySharedService.getRoleCapabilities(partyId)
          .then((roleCapabilities) => { 
            roleCapabilities.partyRoles.forEach(el => {
              if (el.name === 'cartus-employee') {
                this.isNotCartusEmp = false;
                return false;
              }
            });
          }));
        }
      },
      error: (err: HttpErrorResponse) => {
          this.spinner.hide();
    }});
  }

  gotoChangePasswordPage() {
    const changePasswordURL = String(this.appConfig.getConfig('changePassword'));
    const url = this.router.url.length > 1 ? this.router.url.slice(1) : this.router.url;
    this.router.navigate(['/externalRedirect', { externalUrl: `${changePasswordURL}&urlPath=${url}` }], {
      skipLocationChange: true
    });
  }

  getPhoneNumber(phoneNumber) {
    const regex = /^[A-Za-z0-9 ]+$/;
    if ( regex.test(phoneNumber) ) {
      return Number(phoneNumber);
    } else {
       return phoneNumber;
    }
  }
}
