<div class="filter-modal-wrap">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="mat-dialog-title">
        <h2>Filter Table</h2><button mat-icon-button mat-dialog-close><span class="material-icons close-icon">close</span></button>
    </div>
    <div class="mat-dialog-content">
        <div fxLayout fxLayout.lt-md="column" fxLayoutAlign="space-between center" fxLayoutAlign.gt-md="flex-start" fxLayoutGap.gt-md="25px">
            <div fxFlex="25%"  class="filestatus-filter">
                <h6>File Status:</h6>
            </div>
            <div fxFlex="25%"  class="departure-filter">
                <h6>Departure:</h6>
            </div>
            <div fxFlex="25%"  class="destination-filter">
                <h6>Destination:</h6>
            </div>
            <div fxFlex="25%" class="policy-filter">
                <h6>Policy:</h6>
            </div>
        </div>
    </div>
    <div class="mat-dialog-actions">
        <div class="filter-buttons" fxLayout="row" fxLayoutAlign="end center">      
            <button mat-button class="text-button" id="reset" > RESET </button>
            <button mat-button class="contained-button" id="save" > OK </button>
        </div>
    </div>
</div>

