import { Component, OnInit, HostBinding, ViewEncapsulation } from '@angular/core';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import * as constants from '../../../models/constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit {
  /**property to print copyright string */
  readonly copyright = constants.copyright;
  public termsOfUseUrl: string;
  public privacyNotice: string;
  public contactUs: string;

  @HostBinding('class') class = 'app-footer';

  constructor(private appConfig: AppConfigService) { }

  ngOnInit() {
    this.termsOfUseUrl = this.appConfig.getConfig('baseDomain') + '#/termsOfUse';
    this.privacyNotice = this.appConfig.getConfig('baseDomain') + '#/privacyNotice';
    this.contactUs = this.appConfig.getConfig('baseDomain') + '#/contactUs';
  }

}
