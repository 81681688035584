import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { SupplierDiscountsElements, SupplierCommissionsElements, ContractSiteIdElements} from '../../../models/supplier.model';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SupplierDetailsEditComponent } from './supplier-details-edit/supplier-details-edit.component';
import {  EditContractTerritoryPopupComponent } from './edit-contract-territory-popup/edit-contract-territory-popup.component';
import { AddContractSiteIdPopupComponent } from './add-contract-site-id-popup/add-contract-site-id-popup.component';
import {  AddContractPopupComponent  } from './add-contract-popup/add-contract-popup.component';
import { SuppliersService } from 'src/app/core/services/suppliers.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-supplier-info',
  templateUrl: './supplier-info.component.html',
  styleUrls: ['./supplier-info.component.scss']
})
export class SupplierInfoComponent implements OnInit, AfterViewChecked  {

  /** Holds the supplier name*/
  supplierName: string;
  /**mat accordion panel header height */
  matExpansionHeaderHeight = '40px';

  suppliersDetails: any;

  SUPPLIER_DISCOUNTS_ELEMENT_DATA: SupplierDiscountsElements[] = [
    {DiscountType : 'Micro Move', Discount: '15', StartDate: '2020-09-20', EndDate : '2020-12-30'},
    {DiscountType : 'Micro Move', Discount: '15', StartDate: '2020-09-20', EndDate : '2020-12-30'}
  ];
  SupplierDiscountsColumns: string[] = ['category', 'percentage', 'effectiveFrom', 'effectiveTo'];
  SupplierDiscountsDataSource = this.SUPPLIER_DISCOUNTS_ELEMENT_DATA;

  SUPPLIER_COMMISSIONs_ELEMENT_DATA: SupplierCommissionsElements[] = [
    {CommissionType : 'Micro Move', Commission: '15', StartDate: '2020-09-20', EndDate: '2020-12-30'},
    {CommissionType : 'Micro Move', Commission: '15', StartDate: '2020-09-20', EndDate: '2020-12-30'}
  ];
  SupplierCommissionColumns: string[] = ['category', 'percentage', 'effectiveFrom', 'effectiveTo'];
  SupplierCommissionDataSource = this.SUPPLIER_COMMISSIONs_ELEMENT_DATA;

  CONTRACT_SITEID_ELEMENT_DATA: ContractSiteIdElements[] = [
    {
      siteId: '442443445', country: 'United States of America', streetAddress: '1023 Park Ave',
      city: 'New York', state: 'NY', postalCode: '10019', status: 'Active'
    }
  ];
  ContractSiteIdColumns: string[] = ['siteId', 'country', 'streetAddress', 'city', 'state', 'postalCode', 'status'];
  ContractSiteIdDataSource = this.CONTRACT_SITEID_ELEMENT_DATA;
  contractsData: any;
  supplierStatus: any;
  partyDetails: any;
  contractForm: any;
  contractsFormGroup: UntypedFormGroup;
  servicesDetails: any;
  showContractsByIndex: any = {
    0: true
  };

  constructor(
    public dialog: MatDialog,
    private readonly suppliersService: SuppliersService,
    public spinner: NgxSpinnerService,
    private fb: UntypedFormBuilder,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.getsupplierDetails();
  }

  getsupplierDetails () {
    this.spinner.show();
    this.suppliersDetails = this.suppliersService.getSupplierDetails();
    this.contractsData = this.suppliersDetails && this.suppliersDetails.details &&
     this.suppliersDetails.details.supplierContractData ? this.suppliersDetails.details.supplierContractData : [];
    this.supplierName = this.suppliersDetails &&  this.suppliersDetails.details &&
     this.suppliersDetails.details.party ? this.suppliersDetails.details.party.entityName : '';
    this.supplierStatus = this.suppliersDetails &&  this.suppliersDetails.details &&
     this.suppliersDetails.details.party ? this.suppliersDetails.details.party.status : '';
    this.partyDetails = this.suppliersDetails &&  this.suppliersDetails.details &&
     this.suppliersDetails.details.party ? this.suppliersDetails.details.party : null;
    this.servicesDetails = this.suppliersDetails &&  this.suppliersDetails.details &&
     this.suppliersDetails.details.serviceDetails ? this.suppliersDetails.details.serviceDetails : [];
    this.createContractsForm();
    this.spinner.hide();
  }

  openEditDetailsPopup () {
    const partyDetails = this.partyDetails;
    const dialogRef = this.dialog.open(SupplierDetailsEditComponent, {
      disableClose: false,
      panelClass: ['dialogMainContainer', 'dialogMainContainerSupplierEdit'],
      data: {isModelView: true, action: 'edit', details: partyDetails}
    });
    dialogRef.afterClosed().subscribe(
      (result) => {
        if (result) {
          this.partyDetails = null;
          setTimeout(() => {
            this.partyDetails = result;
          }, 10);
        }
        console.log('dialog closed');
    });
  }

  createContractsForm () {
    this.contractsFormGroup = this.fb.group({
      contractsArray: this.fb.array([]),
    });
    this.pathCantractValues();
    this.contractsFormGroup.disable();
  }

  pathCantractValues() {
    if (this.contractsData && this.contractsData.length) {
      this.contractsData.forEach(element => {
        const formGroup =  this.getContractGroupFrom();
        formGroup.patchValue(element);
        this.patchTerritoryServiceDetails((formGroup.get('territoryServiceDetails') as UntypedFormArray), element['_id']);
        this.patchOracleSiteIdDetails((formGroup.get('oracleSiteIdDetails') as UntypedFormArray), element['_id']);
        (this.contractsFormGroup.get('contractsArray') as UntypedFormArray).push(formGroup);
      });
    }
  }

  getContractGroupFrom () {
    const formGroup =  this.fb.group({
      _id: new UntypedFormControl('', Validators.required),
      kind: new UntypedFormControl(''),
      description: new UntypedFormControl(''),
      effectiveFrom: new UntypedFormControl(''),
      effectiveTo: new UntypedFormControl(''),
      __v: new UntypedFormControl(''),
      createdAt: new UntypedFormControl('') ,
      updatedAt: new UntypedFormControl(''),
      status: new UntypedFormControl(''),
      affiliateRelation: new UntypedFormControl(''),
      affiliateOf: new UntypedFormControl(''),
      affiliateId: new UntypedFormControl(''),
      serviceType: new UntypedFormControl(''),
      email : new UntypedFormControl(''),
      territoryServiceDetails : new UntypedFormArray([]),
      oracleSiteIdDetails : new UntypedFormArray([]),
    });
    return formGroup;
  }

  patchTerritoryServiceDetails (territoryFormArray: UntypedFormArray, contractId?) {
    const servicesDetails = this.servicesDetails;
    if (contractId &&  servicesDetails &&  servicesDetails.length ) {
      servicesDetails.forEach(service => {
        if (service.contractId === contractId) {
          if (service.serviceType === 'territory') {
            territoryFormArray.push(this.getTerritoryCoverageForm(service));
          }
        }
      });
    }
  }

  patchOracleSiteIdDetails (OracleSiteIdFormArray: UntypedFormArray, contractId?) {
    const servicesDetails = this.servicesDetails;
    if (contractId &&  servicesDetails &&  servicesDetails.length ) {
      servicesDetails.forEach(service => {
        if (service.contractId === contractId) {
          if (service.serviceType === 'oracleSiteId') {
            OracleSiteIdFormArray.push(this.getOracleSiteIdsForm(service));
          }
        }
      });
    }
  }

  getOracleSiteIdsForm(value?) {
    const oracleSiteIdForm = new UntypedFormGroup ({
      deliveryConfigurations: new UntypedFormArray([]),
      _id: new UntypedFormControl(''),
      serviceReference: new UntypedFormControl(''),
      contractId: new UntypedFormControl(''),
      serviceType: new UntypedFormControl(''),
      notificationEmailId: new UntypedFormControl(''),
      updatedAt: new UntypedFormControl(''),
      createdAt: new UntypedFormControl('')
    });

    if (value) {
      oracleSiteIdForm.patchValue(value);
      if (value.deliveryConfigurations && value.deliveryConfigurations.length) {
        value.deliveryConfigurations.forEach(element => {
          (oracleSiteIdForm.get('deliveryConfigurations') as UntypedFormArray).push(this.getOracleSiteIdDeliveryConfig(element));
        });
      } else {
        (oracleSiteIdForm.get('deliveryConfigurations') as UntypedFormArray).push(this.getOracleSiteIdDeliveryConfig());
      }
    } else {
      (oracleSiteIdForm.get('deliveryConfigurations') as UntypedFormArray).push(this.getOracleSiteIdDeliveryConfig());
    }
    return oracleSiteIdForm;
  }

  getTerritoryCoverageForm (value?) {
    const territoryForm = new UntypedFormGroup ({
      deliveryConfigurations: new UntypedFormArray([]),
      _id: new UntypedFormControl(''),
      serviceReference: new UntypedFormControl(''),
      contractId: new UntypedFormControl(''),
      serviceType: new UntypedFormControl(''),
      notificationEmailId: new UntypedFormControl(''),
      updatedAt: new UntypedFormControl(''),
      createdAt: new UntypedFormControl(''),
      territory: new UntypedFormControl(''),
      territoryTarrif: new UntypedFormControl('')
    });

    if (value) {
      territoryForm.patchValue(value);
      if (value.deliveryConfigurations && value.deliveryConfigurations.length) {
        value.deliveryConfigurations.forEach(element => {
          (territoryForm.get('deliveryConfigurations') as UntypedFormArray).push(this.getTerritoryDeliveryConfig(element));
        });
      } else {
        (territoryForm.get('deliveryConfigurations') as UntypedFormArray).push(this.getTerritoryDeliveryConfig());
      }
    } else {
      (territoryForm.get('deliveryConfigurations') as UntypedFormArray).push(this.getTerritoryDeliveryConfig());
    }
    return territoryForm;
  }

  getTerritoryDeliveryConfig (element?, formGroup?) {
    const configuration = formGroup ? formGroup : new UntypedFormGroup({
      'region': new UntypedFormControl(''),
      'regulation': new UntypedFormControl(''),
      'tariff': new UntypedFormControl(''),
      'commissions': new UntypedFormArray([]),
      'discounts': new UntypedFormArray([])
    });

    if (element) {
      if (formGroup) {
        (configuration.get('commissions') as UntypedFormArray).clear();
        (configuration.get('discounts') as UntypedFormArray).clear();
      }
      configuration.patchValue(element);
      if (element.commissions && element.commissions.length) {
        element.commissions.forEach((commission, Index) => {
          (configuration.get('commissions') as UntypedFormArray).push(this.getForm(commission));
        });
      } else {
        (configuration.get('commissions') as UntypedFormArray).push(this.getForm());
      }
      if (element.discounts && element.discounts.length) {
        element.discounts.forEach((discount, Index) => {
          (configuration.get('discounts') as UntypedFormArray).push(this.getForm(discount));
        });
      } else {
        (configuration.get('discounts') as UntypedFormArray).push(this.getForm());
      }
    } else {
      (configuration.get('discounts') as UntypedFormArray).push(this.getForm());
      (configuration.get('commissions') as UntypedFormArray).push(this.getForm());
    }
    return configuration;
  }

  getOracleSiteIdDeliveryConfig (element?, formData?) {
    const configuration = formData ? formData : new UntypedFormGroup({
      'region': new UntypedFormControl(''),
      'siteId': new UntypedFormControl(''),
      'country':  new UntypedFormControl(''),
      'streetAddress': new UntypedFormControl(''),
      'city': new UntypedFormControl(''),
      'state': new UntypedFormControl(''),
      'postalCode': new UntypedFormControl(''),
      'status': new UntypedFormControl(''),
    });

    if (element) {
      configuration.patchValue(element);
    }
    return configuration;
  }

  getForm (element?) {
    const formGroup =  new UntypedFormGroup ({
      category: new UntypedFormControl(''),
      percentage: new UntypedFormControl(''),
      effectiveFrom: new UntypedFormControl(''),
      effectiveTo: new UntypedFormControl(''),
    });

    if (element) {
      formGroup.patchValue(element);
    }
    return formGroup;
  }

  openTeritoryDetails(territory: AbstractControl, index: number) {
    this.addContractTerritory(territory, index);
  }

  addContractTerritory (formData: AbstractControl, index?: number) {
    const modalData = formData && index > -1 && formData.get('deliveryConfigurations') && formData.get('deliveryConfigurations')
    .get('' + index) ? formData.get('deliveryConfigurations').get('' + index) : this.getTerritoryDeliveryConfig();
    const dialogRef = this.dialog.open(EditContractTerritoryPopupComponent, {
      disableClose: false,
      panelClass: ['dialogMainContainer', 'dialogMainContainerTerrotry'],
      data: modalData,
      height: 'auto',
      width: '500px',
    });
    dialogRef.afterClosed().subscribe(
      (result) => {
        if (result && formData && index !== undefined && index > -1) {
          this.getTerritoryDeliveryConfig(result, formData.get('deliveryConfigurations').get('' + index));
        } else if (result && index === undefined) {
          if (!formData.get('territoryServiceDetails').get('' + 0)) {
            (formData.get('territoryServiceDetails') as UntypedFormArray).push(this.getTerritoryCoverageForm());
            this.getTerritoryDeliveryConfig(result, formData.get('territoryServiceDetails').get('' + 0)
            .get('deliveryConfigurations').get('' + 0));
          } else {
            (formData.get('territoryServiceDetails').get('' + 0).get('deliveryConfigurations') as UntypedFormArray)
            .push(this.getTerritoryDeliveryConfig(result));
          }
        }
        console.log(result, index);
        console.log('dialog closed');
    });
  }

  removeTerritory (form: AbstractControl, index) {
    (form.get('deliveryConfigurations') as UntypedFormArray).removeAt(index);
  }

  addSiteId ( formData: AbstractControl, index?: number) {
    const dialogRef = this.dialog.open(AddContractSiteIdPopupComponent, {
      disableClose: false,
      panelClass: ['dialogMainContainer'],
      data: formData ?  formData : this.getOracleSiteIdDeliveryConfig(),
      height: '393px',
      width: '696px',
    });
    dialogRef.afterClosed().subscribe(
      (result) => {
        if (result && formData) {
          formData.patchValue(result);
        } else if (result && index !== undefined && index > -1) {
          if (!this.contractsFormGroup.get('contractsArray').get('' + index).get('oracleSiteIdDetails').get('' + 0)) {
            (this.contractsFormGroup.get('contractsArray').get('' + index)
            .get('oracleSiteIdDetails') as UntypedFormArray).push(this.getOracleSiteIdsForm());
            this.getOracleSiteIdDeliveryConfig(result, this.contractsFormGroup.get('contractsArray')
            .get('' + index).get('oracleSiteIdDetails').get('' + 0).get('deliveryConfigurations').get('' + 0));
          } else {
            (this.contractsFormGroup.get('contractsArray').get('' + index).get('oracleSiteIdDetails')
            .get('' + 0).get('deliveryConfigurations') as UntypedFormArray).push(this.getOracleSiteIdDeliveryConfig(result));
          }
        }
        console.log('dialog closed');
    });
  }

  addContract (contractData?: AbstractControl, Index?: number) {
    const dialogRef = this.dialog.open(AddContractPopupComponent, {
      disableClose: false,
      panelClass: ['dialogMainContainer'],
      data: contractData ? contractData : this.getContractGroupFrom(),
      width: '433px',
      height: '81vh'
    });
    dialogRef.afterClosed().subscribe(
      (result: UntypedFormGroup) => {
        if (result) {
          if (Index > -1) {
            (this.contractsFormGroup.get('contractsArray') as UntypedFormArray).get('' + Index).patchValue(result.getRawValue());
          } else {
            (this.contractsFormGroup.get('contractsArray') as UntypedFormArray).push(result);
          }
        }
        console.log('dialog closed');
    });
  }

  updateContractEmail (contract: AbstractControl, index: number) {
    this.addContract(contract, index);
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  getTableData (control: UntypedFormArray) {
    return control.getRawValue();
  }

}
