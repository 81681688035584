import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-add-contract-site-id-popup',
  templateUrl: './add-contract-site-id-popup.component.html',
  styleUrls: ['./add-contract-site-id-popup.component.scss']
})
export class AddContractSiteIdPopupComponent implements OnInit {

  countryList: any[] = [
    {
      displayValue: 'United States',
      value: 'US'
    }
  ];

  statesList: any[] = [{
    displayValue: 'New York',
    value: 'NY'
  }];
  siteIdFormGroup: UntypedFormGroup;
  isEditView: boolean;

  constructor(public dialogRef: MatDialogRef<AddContractSiteIdPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: UntypedFormGroup) {

  }

  ngOnInit() {
    if (this.dialogData) {
      console.log(this.dialogData);
      this.siteIdFormGroup = this.getNewSiteIdForm(this.dialogData.getRawValue());
      this.siteIdFormGroup.enable();
      if (this.siteIdFormGroup.get('siteId') && this.siteIdFormGroup.get('siteId').value) {
        this.isEditView = true;
      }
    }
  }

  onCloseClick () {
    this.dialogRef.close();
  }

  saveChanges () {
    this.dialogRef.close(this.siteIdFormGroup.getRawValue());
  }

  removeSiteId (configIndex, discountsIndex) {
    (this.siteIdFormGroup.get('deliveryConfigurations') as UntypedFormArray).removeAt(discountsIndex);
  }

  addNewSiteId (configIndex) {
    (this.siteIdFormGroup.get('deliveryConfigurations') as UntypedFormArray).push(this.getNewSiteIdForm());
  }


  getNewSiteIdForm (val?) {
    const configuration =  new UntypedFormGroup({
      'region': new UntypedFormControl(''),
      'siteId': new UntypedFormControl(''),
      'country':  new UntypedFormControl(''),
      'streetAddress': new UntypedFormControl(''),
      'city': new UntypedFormControl(''),
      'state': new UntypedFormControl(''),
      'postalCode': new UntypedFormControl(''),
      'status': new UntypedFormControl(''),
    });
    if (val) {
      configuration.patchValue(val);
    }

    return configuration;
  }

  isFormInvalid () {
    return !this.siteIdFormGroup.get('siteId').value || !this.siteIdFormGroup.get('country').value ||
     !this.siteIdFormGroup.get('streetAddress').value || !this.siteIdFormGroup.get('city').value ||
     !this.siteIdFormGroup.get('state').value || !this.siteIdFormGroup.get('postalCode').value;
  }

}
