<ngx-spinner type="ball-spin-clockwise-fade" size="medium">
</ngx-spinner>

<section fxLayout="column">
    <h1 class="explore-title-text">Account Settings</h1>
</section>

<app-candidate-contact-info *ngIf="candidateContactInfo" [candidateContactInfo]="candidateContactInfo" [candidateCompany]="candidateCompany">
</app-candidate-contact-info>

<mat-card *ngIf="isNotCartusEmp" class="mat-card-candidate-contact clickable" (click)="gotoChangePasswordPage()" fxLayout fxLayoutAlign="start center" fxLayoutAlign.xs="start start">
    <span><a>Change Password</a></span>
    <!-- TODO: The below Last Changed Date div should be implemented Later -->
</mat-card>