import { NgxSpinnerService } from 'ngx-spinner';
import {
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Selection } from '../../../../core/models/selection.model';
import { Subscription, Subject } from 'rxjs';
import { NotificationsService } from '../../../../core/services/notifications.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { LoggerService } from '../../../../core/services/logger.service';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { FilterModalComponent } from '../../filter-modal/filter-modal.component';
import { WorkOrderService } from '../../../services/work-order.service';
import {WorkOrder} from '../../../models/work-order.model';
import { WorkOrderListColumnPopUpComponent } from './work-order-list-column-pop-up/work-order-list-column-pop-up.component';
import { skipRecordCount } from 'src/app/core/models/constants';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';

/**Class for WorkOrderDetailsComponent */
@Component({
  selector: 'app-work-order-list',
  templateUrl: './work-order-list.component.html',
  styleUrls: ['./work-order-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class WorkOrderListComponent implements OnInit, OnDestroy {
  /**stores the details of work order */
 workOrder: WorkOrder;
 workOrderSubscription: Subscription;
 workOrders: WorkOrder[] = [];
 /**Data source for table */
 dataSource: MatTableDataSource<WorkOrder>;

  /**variable to store the title */
  displayedColumns: string[] = [
    'partyName',
    'orderReference',
    'departureState',
    'destinationState',
    'workOrderStatusDate'
  ];
  columnList: string[] = [
    'partyName',
    'orderReference',
    'departureState',
    'destinationState',
    'company',
    'bookedWithContactName',
    'workOrderStatusDate'
  ];
  selectedCols: Selection[];
   /** To sort the mat table columns */
   @ViewChild(MatSort, { static: false }) set sortOrder(sort: MatSort) {
    if (sort) {
      this.dataSource.sort = sort;
    }
  }
  /** To paginate in a mat table */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  private searchterms = new Subject<string>();
   /** store default column for sorting */
  sortBy = 'statusDate';
  /** sorting direction */
  sortDir = 'DESC';
  /**check if table data is loaded */
  isLoaded = false;
  totalWorkOrder: string;
  /**store levelDetails */
  // levelDetails: Level[] = [];
  /** Subscription prop for unsubscribing services */
  private readonly subscription: Subscription = new Subscription();
  /**stores count of active candidates */
  initialCount = 0;
  /** Stores the client contact ID */
  clientContactId: string;
  pageInfo = {
    pageSize: 20,
    pageIndex: 0,
    totalCount: 0
  };
  departureState = 'departure.state';
  errorMessage = 'We are unable to fetch Work Order List at this time. Please try again later.';

  /**store the filter value */
  filterText = '';

  /** accesssibility statement */
  sortStatement = '';

  filterDialogRef: MatDialogRef<FilterModalComponent>;
  announceStmt: string;
  /**
   * injecting dependencies
   * @param dialog object for matdialog
   * @param  candidateProfilesService object for CandidateProfileService
   * @param changeDetectorRefs object for changeDetectorRefs object
   * @param snackBar object for snachbar
   * @param datePipe object for datePipe object

   * @param spinner object for spinner

   */
  constructor(
    public dialog: MatDialog,
    private readonly workOrderService: WorkOrderService,
    private readonly changeDetectorRefs: ChangeDetectorRef,
    public snackBar: MatSnackBar,
    public datePipe: DatePipe,
    private readonly _router: Router,
    public spinner: NgxSpinnerService,
    private readonly Logger: LoggerService,
    private readonly notificationsService: NotificationsService
  ) {}

  loadWorkOrders(pageSize: number = 20, skipRecords: number = 0) {
    this.spinner.show();
    this.announceStmt = 'Loading, please wait.';
    this.workOrderSubscription =
     this.workOrderService.getAllWorkOrders(this.filterText, skipRecords, pageSize, this.sortBy, this.sortDir).subscribe({
      next: data => {
        this.workOrders = data.workOrders;
        this.workOrders.forEach((workOrder: WorkOrder) => {
          workOrder.partyName = `${workOrder.orderedBy.partyName.names[1].charAt(0).toUpperCase()}${workOrder.orderedBy.partyName.names[1].slice(1)}, ${workOrder.orderedBy.partyName.names[0]}`;
          workOrder.destinationCity = workOrder.destinationCity.charAt(0).toUpperCase() + workOrder.destinationCity.slice(1);
          workOrder.departureCity = workOrder.departureCity.charAt(0).toUpperCase() + workOrder.departureCity.slice(1);
          workOrder.company = (workOrder.onBehalfOf.preferredName) ?
           workOrder.onBehalfOf.preferredName : (workOrder.onBehalfOf.entityName ? workOrder.onBehalfOf.entityName : `-`);
          workOrder.workOrderStatusDate = (workOrder.workOrderStatusDate) ?
           (workOrder.workOrderStatusDate) : workOrder.orderAcknowledgementDate;
          setTimeout(() => {
            this.paginator.length = data.totalWorkOrders;
          });
        this.dataSource = new MatTableDataSource(this.workOrders);
        this.announceStmt = this.workOrders.length > 0 ? `${this.workOrders.length} work orders found` : 'No records found';
        this.dataSource.sort = this.sortOrder;
        this.totalWorkOrder = data.totalWorkOrders.toString();
        this.spinner.hide();
        });
      },
      error: (err: HttpErrorResponse) => {
        this.spinner.hide();
        this.announceStmt = 'An error occurred. Please try again later.';
        console.log('HTTP Error', err);
        this.notificationsService.flashNotification(
          'error',
          err.message,
          true,
          'dismiss'
        );
      }
    });
  }

  /**To initialize the component */
  ngOnInit() {
    this.loadWorkOrders();
    this.subscribeTosearchTerm();
  }
  subscribeTosearchTerm() {
    this.searchterms.pipe(
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe((text) => {
      if (text.length >= 2) {
        this.paginator.pageIndex = skipRecordCount;
        this.loadWorkOrders(this.paginator.pageSize);
      } else {
        this.loadWorkOrders(this.paginator.pageSize, this.paginator.pageIndex * this.paginator.pageSize);
      }
    });
  }

  applyFilter(searchTerm) {
    if (searchTerm.length >= 2) {
      this.searchterms.next(searchTerm);
    } else {
      this.searchterms.next('');
    }
  }

  sortData(event) {
    switch (event.active) {
      case 'partyName':
        event.active = 'fullname';
        break;
      case 'departureState':
        event.active = 'departure';
        break;
      case 'destinationState':
        event.active = 'destination';
      break;
      case 'workOrderStatusDate':
        event.active = 'statusDate';
      break;
      case 'bookedWithContactName':
        event.active = 'agentName';
      break;
    }
    switch (event.direction) {
      case 'asc':
        this.sortStatement = `sorting ${event.active} in ascending order`;
        break;
      case 'desc':
        this.sortStatement = `sorting ${event.active} in descending order`;
        break;
      case '':
        this.sortStatement = `sorting ${event.active} in random order`;
        break;
    }
    this.sortBy = event.active;
    this.sortDir = event.direction === 'desc' ? 'DESC' : 'ASC';
    this.loadWorkOrders(this.pageInfo.pageSize, this.paginator.pageIndex * this.paginator.pageSize);
    switch (event.active) {
      case 'fullname':
        this.sortBy = 'partyName';
        break;
      case 'departure':
        this.sortBy = 'departureState';
        break;
      case 'destination':
        this.sortBy = 'destinationState';
      break;
      case 'statusDate':
        this.sortBy = 'workOrderStatusDate';
      break;
      case 'agentName':
        this.sortBy = 'bookedWithContactName';
      break;
    }
  }

  /**Refresh and detect the changes */
  refresh() {
    this.changeDetectorRefs.detectChanges();
  }

  /** navigate to work order details of requested work order*/
  navigateToWorkOderDetails(workOrder: WorkOrder, event) {
    event.preventDefault();
    event.stopPropagation();
    this._router.navigate(['work-order/detail'], {
      state: workOrder
    });
  }

  /**
   * destroys the object
   */
  ngOnDestroy() {
    this.workOrderSubscription.unsubscribe();
  }

  onPagination(event: any) {
    this.pageInfo.pageSize = this.paginator.pageSize;
    this.pageInfo.totalCount = this.paginator.length;
     console.log(this.paginator.pageSize);
     console.log(this.paginator.pageIndex);
     this.loadWorkOrders(this.paginator.pageSize, this.paginator.pageIndex * this.paginator.pageSize);
  }

   /** Method to open column selection dialog**/
   openTableOptions() {
    const dialogRef = this.dialog.open(WorkOrderListColumnPopUpComponent, {
      disableClose: false,
      panelClass: ['dialogMainContainer'],
      data: this.displayedColumns
    });
    this.subscription.add(
      dialogRef.componentInstance.columnsListUpdated.subscribe(
        (result: Selection[]) => {

          this.selectedCols = result;
          this.updateTable();
        })
    );

  }

  /** This method is to update the table display columns based on column selection**/
  updateTable(): void {

    this.displayedColumns = Object.assign([], this.columnList);

    this.columnList.forEach((item, index) => {
      const checkedvalue = this.selectedCols.some(val => {
        return val.value === item;
      });
      if (!checkedvalue) {
        const itemIndex = this.displayedColumns.findIndex(x => x === item);
        this.displayedColumns.splice(itemIndex, 1);
      }

    });

  }

 resetList() {
    this.filterText = '';
    this.spinner.show();
    this.announceStmt = 'Loading, please Wait.';
    this.searchterms.next(this.filterText);
  }

}
