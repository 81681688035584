import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import { HotelLeadsFormService } from '../../../services/hotel-leads-form.services';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationsService } from '../../../../core/services/notifications.service';
import { HotelLeadsFormComponent } from '../hotel-leads-form/hotel-leads-form.component';
import { MatDialog , MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatPaginator} from '@angular/material/paginator';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-hotel-leads-list',
  templateUrl: './hotel-leads-list.component.html',
  styleUrls: ['./hotel-leads-list.component.scss']
})
export class HotelLeadsListComponent implements OnInit {

  leadsListData = [];

  displayedColumns = [
    'customerFileId',
    'customerName',
    'clientNumber',
    'clientName',
    'supplierName',
    'enteredBy'
  ];
  isNoRecordsFound = false;

  pageInfo = {
    pageSize: 10,
    totalCount: 0,
    currentPage: 0,
    sortField: null,
    sortFieldDir: null,
    pageSizeOptions: ['10', '20', '25']
  };
  filterDetails: any;
  onlyShowMyOrders: any;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  constructor(private hotelLeadsFormService: HotelLeadsFormService,
    public spinner: NgxSpinnerService,
    private readonly notificationsService: NotificationsService,
    public dialog: MatDialog) {

  }

  ngOnInit() {
      this.onlyShowMyOrders = true;
      this.displayLeadsList();
  }

  displayLeadsList() {
    this.spinner.show();
    const params = this.createSeacrhParams() ? this.createSeacrhParams() : '';
    this.hotelLeadsFormService.getLeadsListData(params).subscribe({
      next: response => {
      this.spinner.hide();
      if (response.headers.get('x-order-count') && parseInt(response.headers.get('x-order-count'), 10) > 0) {
        if (response && response.body && response.body.length) {
          this.pageInfo.totalCount = parseInt(response.headers.get('x-order-count'), 10);
          this.leadsListData = response.body;
          this.isNoRecordsFound = false;
        } else {
          this.isNoRecordsFound = true;
          this.enableNotification('error', 'No records found.');
        }
      } else {
        this.isNoRecordsFound = true;
        this.enableNotification('error', 'No records found.');
      }
    }, 
    error: (error: HttpErrorResponse) => {
      this.spinner.hide();
      this.isNoRecordsFound = true;
    }});
  }

  getTheLeadDetails(element) {
    if (element.orderId) {
      this.getLeadByOrderId(element.orderId);
    }
  }

  getLeadByOrderId(orderId) {
    this.spinner.show();
    this.hotelLeadsFormService.getLeadByOrderId(orderId).subscribe({
      next: data => {
      this.spinner.hide();
      if (typeof data === 'object' && Object.keys(data).length) {
        this.openLeadForm(data);
      } else {
        this.enableNotification('error', 'Oops! something went wrong.');
      }
    }, 
    error: (error: HttpErrorResponse) => {
      this.spinner.hide();
      this.enableNotification('error', 'Oops! something went wrong.');
      console.log(error.message);
    }});
  }

  onPagination(event) {
    if (this.pageInfo.currentPage !== event.pageIndex || this.pageInfo.pageSize !== event.pageSize) {
      this.pageInfo.currentPage = event.pageIndex;
      this.pageInfo.pageSize = event.pageSize;
      this.displayLeadsList();
    }
  }

  sortData(event) {
    this.pageInfo.sortField = event.active === 'customerFileId' ?
     'details.customerFileId' :  event.active === 'clientNumber' ?
      'onBehalfOf.clientNumber' : event.active === 'clientName' ?
       'onBehalfOf.entityName' : 'bookedWith.entityName';
    this.pageInfo.sortFieldDir = event.direction.toUpperCase();
    this.displayLeadsList();
  }

  openLeadForm(formData) {
    const dialogRef = this.dialog.open(HotelLeadsFormComponent, {
      disableClose: false,
      height: 'auto',
      width: 'auto',
      panelClass: ['dialogMainContainer'],
      data: {leadData: formData}
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  createSeacrhParams() {
    let params = '';
    if (this.pageInfo.currentPage > -1) {
      params = `skip=${this.pageInfo.currentPage}`;
    }
    if (this.pageInfo.pageSize) {
      params = params ?
       params + `&limit=${this.pageInfo.pageSize}` : `limit=${this.pageInfo.pageSize}`;
    }
    if ( this.pageInfo.sortField) {
      params = params ?
       params + `&sortField=${this.pageInfo.sortField}` : `sortField=${this.pageInfo.sortField}`;
    }
    if ( this.pageInfo.sortFieldDir) {
      params = params ?
       params + `&sortDir=${this.pageInfo.sortFieldDir}` : `sortDir=${this.pageInfo.sortFieldDir}`;
    }
    if (this.filterDetails && this.filterDetails.includes('customerFileId%7C')) {
      params = params ? params + `&details=${this.filterDetails}` : `details=${this.filterDetails}`;
    }
    if (this.onlyShowMyOrders) {
      params = params ? params + `&onlyShowMyOrders=${this.onlyShowMyOrders}` : `onlyShowMyOrders=${this.onlyShowMyOrders}`;
    }
    return params ? '?' + params : null;
  }

  applyFilter(val?) {
    if (val && val.length >= 7) {
      this.filterDetails = 'customerFileId%7C' + val;
      this.pageInfo.currentPage = 0;
      if (this.paginator) {
        this.paginator.firstPage();
      }
      this.displayLeadsList();
    } else {
      this.filterDetails = val;
    }
    if (!val || !val.length) {
      this.displayLeadsList();
    }
  }

  submissionValChange() {
    this.pageInfo.currentPage = 0;
    this.paginator.firstPage();
    this.displayLeadsList();
  }

  enableNotification(type: string, msg: string) {
    this.notificationsService.flashNotification(
      type,
      msg,
      true,
      'dismiss'
    );
  }

}
