import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import * as constants from '../../../core/models/constants';
import { AppConfigService } from '../../services/app-config.service';

@Component({
  selector: 'app-non-compatible',
  templateUrl: './non-compatible.component.html',
  styleUrls: ['./non-compatible.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NonCompatibleComponent implements OnInit {
  /**property to print copyright string */
  readonly copyright = constants.copyright;
  public termsOfUseUrl: string;
  public privacyNotice: string;
  public contactUs: string;

  constructor(private appConfig: AppConfigService) { }

  ngOnInit() {
    this.termsOfUseUrl = this.appConfig.getConfig('baseDomain') + 'assets/terms-of-use.html';
    this.privacyNotice = this.appConfig.getConfig('baseDomain') + 'assets/privacy-notice.html';
    this.contactUs = this.appConfig.getConfig('baseDomain') + 'assets/contact-us.html';

  }

}
