import { Injectable, OnDestroy } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { TokenPayload } from '../components/../models/tokenPayload.model';
import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService implements OnDestroy {
  /** Variable to store the token  */
  public authClient: OktaAuth;
  /** Variable to store the transaction*/
  private transaction: any;

  constructor (
    public readonly appConfig: AppConfigService) {
      const authOpts: OktaAuthOptions = {
      clientId: (`${this.appConfig.getConfig('oktaClientId')}`).toString(),
      issuer: (`${this.appConfig.getConfig('oktaUrl')}`).toString(),
      redirectUri: (`${this.appConfig.getConfig('oktaRedirectUri')}`).toString(),
      postLogoutRedirectUri: (`${this.appConfig.getConfig('oktaRedirectUri')}`).toString(),
      tokenManager: {
        storage: 'sessionStorage',
        autoRenew: false
      },
    };
    this.authClient = new OktaAuth(authOpts);
    this.authClient.authStateManager.subscribe(authState => {
    // handle emitted latest authState
    });
    this.authClient.authStateManager.updateAuthState();
  }

  ngOnDestroy(): void {
    if (this.authClient.authStateManager.subscribe) {
      this.authClient.authStateManager.unsubscribe();
    }
  }

  /** Service call for login */
  async login(user: TokenPayload) {
    try {
      const data = {
        username: user.email,
        password: user.password
      };
      this.transaction = await this.authClient.signInWithCredentials(data);
      return await this.getToken(this.transaction);
    } catch (err) {
      return err;
    }
  }

  async isAuthenticated() {
    // Checks if there is a current accessToken in the TokenManger.
    return this.authClient.tokenManager.get('accessToken');
  }

  /** Service call for to get tokens */
  async getToken(transactions) {
    let receivedTokens;
    if (transactions && transactions.status === 'SUCCESS') {
      await this.authClient.token.getWithoutPrompt({
        responseType: ['code', 'token', 'id_token'],
        sessionToken: transactions.sessionToken,
        scopes: ['openid', 'offline_access', 'email'],
      })
      .then(res => {
        receivedTokens = res.tokens;
        if (receivedTokens.idToken && receivedTokens.accessToken) {
          this.authClient.tokenManager.add('idToken', receivedTokens.idToken);
          this.authClient.tokenManager.add('accessToken', receivedTokens.accessToken);
        }
      });
    }
    return {
      transaction: transactions,
      tokens: receivedTokens
    };
  }

  /** Service call to refresh session */
  async refreshSession() {
    return this.authClient.token.getWithoutPrompt().then(tokenOrTokens => {
      if (tokenOrTokens) {
        this.authClient.tokenManager.setTokens({
          accessToken: tokenOrTokens.tokens.accessToken,
          idToken: tokenOrTokens.tokens.idToken
        });
      }
      console.log('getWithoutPrompt()', tokenOrTokens); // Leave this debug code in place
      return tokenOrTokens;
    }).catch(err => {
      // console.error(err); // Leave this debug code in place -- commented out due to error being displayed regardless
      return; // Not authenticated
    });
  }

}
