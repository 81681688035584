<ngx-spinner type="ball-spin-clockwise-fade" size="medium"></ngx-spinner>
<div class="candidateprofile-nav" aria-label="breadcrumbs">
  <a [routerLink]="['/quotes-request']" #breadCrumbNav aria-label="Back to Quote Requests" tabindex="0">Quote Requests</a>
  <span role="navigation" aria-current="currentpage" aria-atomic="true" aria-live="assertive" class="current">{{candidateName}}</span>
</div>
<!--Section To Candidate Profiles-->
<div class="budget-container">
  <div class="budget-content-container">

    <app-candidate-contact-info *ngIf="candidateContactInfo" [candidateContactInfo]="candidateContactInfo">
    </app-candidate-contact-info>

    <mat-accordion class="candidate-review-panel candidate-info-panel" multi="true">
      <!--Candidate Details starts-->
      <mat-expansion-panel class="candidate-detail" tabindex="0">
        <mat-expansion-panel-header [collapsedHeight]="matExpansionHeaderHeight"
          [expandedHeight]="matExpansionHeaderHeight">
          <span class="panel-heading">Details</span>
        </mat-expansion-panel-header>
        <mat-panel-description>
          <app-candidate-info *ngIf="candidateInfo" [candidateInfo]="candidateInfo" class="full-stretch">
          </app-candidate-info>
        </mat-panel-description>
      </mat-expansion-panel>
    </mat-accordion>

    <mat-accordion class="candidate-review-panel move-departure-panel" multi="true">
      <!--Move Details starts-->
      <mat-expansion-panel class="move-detail-panel" tabindex="0">
        <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="matExpansionHeaderHeight" fxLayout
          fxLayout.xs="row" fxLayoutGap="10px">
          <div fxFlex="34%" class="panel-heading">Move Details</div>
          <div *ngIf="candidateMoveInfo" fxFlex="66%" class="header-right-node move hide-on-expand">
            <span>{{candidateMoveInfo.departure.fullAddress}}</span>
            <mat-icon class="material-icons arrow">chevron_right</mat-icon>
            <span>{{candidateMoveInfo.destination.fullAddress}}</span>
          </div>
        </mat-expansion-panel-header>
        <mat-panel-description class="remove-margin">
          <app-candidate-move-info *ngIf="candidateMoveInfo" [candidateMoveInfo]="candidateMoveInfo"
            class="full-stretch"></app-candidate-move-info>
        </mat-panel-description>
      </mat-expansion-panel>
      <!--Departure & Home Details starts-->
      <mat-expansion-panel tabindex="0">
        <mat-expansion-panel-header [collapsedHeight]="matExpansionHeaderHeight"
          [expandedHeight]="matExpansionHeaderHeight" fxLayout fxLayout.xs="row" fxLayoutGap="10px">
          <div fxFlex="80%" class="panel-heading">Departure Home Details</div>
        </mat-expansion-panel-header>
        <mat-panel-description class="remove-margin">
          <app-candidate-departure-home-detail *ngIf="candidateDepartureInfo"
            [candidateDepartureInfo]="candidateDepartureInfo" class="full-stretch">
          </app-candidate-departure-home-detail>
        </mat-panel-description>
      </mat-expansion-panel>
<!-- Quote response  -->
      <mat-expansion-panel *ngIf="quoteResponseInfo" tabindex="0">
        <mat-expansion-panel-header [collapsedHeight]="matExpansionHeaderHeight"
          [expandedHeight]="matExpansionHeaderHeight" fxLayout fxLayout.xs="row" fxLayoutGap="10px">
          <div fxFlex="80%" class="panel-heading">Quote Response</div>
        </mat-expansion-panel-header>
        <mat-panel-description class="remove-margin">
          <app-candidate-quote-info [quoteResponse]="quoteResponseInfo"  class="full-stretch">
          </app-candidate-quote-info>
        </mat-panel-description>
      </mat-expansion-panel>
    </mat-accordion>
    
  </div>
  <div class="budget-footer" *ngIf="isQuotePending" >

    <div class="item">
      <button mat-button aria-label="Accept" class="mat-button contained-button" (click)="onAcceptClick()" id="Authorize_Accept">
        Accept
      </button> &nbsp;&nbsp;
      <button mat-button aria-label="Accept and Respond" class="mat-button contained-button" (click)="openQuoteRequestResponseDialog()" id="Authorize_Accept_Respond">
        Accept & Respond
      </button>
    </div>

  </div>

  <div class="budget-footer" *ngIf="(!isQuotePending) && (isQuoteAccepted)">

    <div class="item">
      <button mat-button aria-label="Respond to Quote" class="mat-button contained-button" (click)="openQuoteRequestResponseDialog()" id="Authorize">
        Respond to Quote
      </button>
    </div>

  </div>
</div>