import { Component, Input } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-session-pop-up',
  templateUrl: './session-pop-up.component.html',
  styleUrls: ['./session-pop-up.component.scss']
})
export class SessionPopUpComponent  {
  @Input() countMinutes: number;
  @Input() countSeconds: number;
  @Input() progressCount: number;
  color: any;
  mode: any;


  constructor(public dialogRef: MatDialogRef<SessionPopUpComponent>, ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
