<mat-card class="mat-card-candidate-contact" fxLayout fxLayoutAlign="start center" fxLayoutAlign.xs="start start">
    <div class="candidate-short-name">{{candidateInitials}}</div>
    <div class="candidate-contact-detail">
        <h1 class="candidate-user-name">{{candidateName}}</h1>
        <div *ngIf="candidateCompany"><span>Company:&nbsp;</span>{{candidateCompany}}</div>
        <div class="contact-detail" fxLayout fxLayout.xs="column">
            <div fxLayout>
                <img alt="phone-icon" src="../../../../../assets/images/candidate-assessment/baseline-phone-24px.svg" />
                <app-sensitive-info-mask *ngIf="phoneNumber" type="phone" [value]="phoneNumber" [dialingCode]="countryDialingCode"></app-sensitive-info-mask>
            </div>
            <div fxLayout>
                <img alt="email-icon" src="../../../../../assets/images/candidate-assessment/baseline-email-24px.svg" />
                <app-sensitive-info-mask *ngIf="emailId" type="email" [value]="emailId"></app-sensitive-info-mask>
            </div>
        </div>
    </div>
</mat-card>
